import { useState } from 'react';
import MeetingsList from './MeetingsList';
import Pagination from './Pagination';
import SearchFilters from './SearchFilters';
import Spinner from '../utilities/Spinner';

const RessourcesContent = ({
  startDate,
  endDate,
  search,
  group,
  setStartDate,
  setGroup,
  setSearch,
  setEndDate,
  loading,
  docs,
  title,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [productsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  let currentSeance = docs.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const [filters, setFilters] = useState({
  //   startDate: '',
  //   endDate: '',
  //   title: '',
  //   groupName: '',
  //   subGroup: '',
  //   chief: '',
  //   policy: '',
  //   includeClosedGroups: false,
  // });

  return (
    <div>
      <div className=' w-[95%] lg:w-[80%] mx-auto'>
        <div className=' py-4'>
          <h2>{title} </h2>
        </div>
      </div>
      <div className=' bg-[#fafafa] mb-5'>
        <div className='w-[95%] lg:w-[80%] mx-auto flex lg:flex-row  flex-col p-1 md:p-4'>
          <div className=' w-[100%] lg:w-[22%]'>
            <h1 className='text-[1.1rem] font-bold mb-4'>
              Options de recherche
            </h1>
            <SearchFilters
              startDate={startDate}
              endDate={endDate}
              search={search}
              group={group}
              setStartDate={setStartDate}
              setGroup={setGroup}
              setSearch={setSearch}
              setEndDate={setEndDate}
            />
          </div>
          {loading ? (
            <div className=' flex-col text-center  items-center flex justify-center h-[30vh] w-[100%]'>
              <Spinner loading={loading} />
            </div>
          ) : (
            <div className='w-[100%] lg:w-[78%]'>
              <div className=' mb-3 border-b-2 border-gray-600'>
                <h2 className='text-[1.1rem] font-bold my-4'>
                  {title} ({docs.length})
                </h2>
              </div>
              <MeetingsList meetings={currentSeance} />
              <Pagination
                documents={docs}
                productsPerPage={productsPerPage}
                currentPage={currentPage}
                paginate={paginate}
                indexOfLastProduct={indexOfLastProduct}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RessourcesContent;
