import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionContent from '../componants/OurMission/MissionContent';
import JoindUs from '../componants/Home/JoindUs';
import Actualities from '../componants/Home/Actualities';
import banner from '../public/images/carousel_7.png';

const NotreMission = () => {
  const pageHeader = {
    title: 'Notre Mission ',
    description:
      'Améliorer la santé publique en promouvant des initiatives et politiques de soins de santé efficaces.',
    banner: banner,
  };

  // const Accordions = [
  //   {
  //     title: 'Conseiller et Accompagne',
  //     subTitle: 'Small states are particularly vulnerable because of their:',
  //     responses: [
  //       'geographic positioning',
  //       'strong dependence on trade',
  //       'limited access to development finance',
  //       'disproportionate impact of natural disasters and climate change.',
  //     ],
  //   },
  //   {
  //     title: 'The Commonwealth defines small states',
  //     subTitle: 'Small states are particularly vulnerable because of their:',
  //     responses: [
  //       'geographic positioning',
  //       'strong dependence on trade',
  //       'limited access to development finance',
  //       'disproportionate impact of natural disasters and climate change.',
  //     ],
  //   },
  // ];

  const heroText = `conseiller et accompagner les gouvernements, organisations de santé et entreprises pour déployer des solutions de santé numériques adaptées, favoriser l'innovation, renforcer les capacités locales, promouvoir l'éthique, et encourager la collaboration entre tous les acteurs.`;

  const listElements = {
    title: 'Soutien et Déploiement des Solutions de Santé Numériques',
    enum: [
      'Conseiller les gouvernements,organisations de santé et entreprises.',
      'Accompagner le déploiement de solutions de santé numériques.',
      'Adapter les solutions aux réalités africaines.',
    ],
  };
  const listElements2 = {
    title: "Renforcement des Capacités et Promotion de l'Éthique",
    enum: [
      'Renforcer les compétences des professionnels de santé et des communautés locales.',
      'Former et transférer des connaissances sur les technologies de santé numériques.',
      'Promouvoir des principes éthiques dans le développement et l’utilisation des solutions numériques.',
      'Assurer la protection des données personnelles et le respect de la vie privée.',
    ],
  };

  const accordions = [
    {
      title: "Évaluer l'Impact",
      content:
        "Mesurer et évaluer l'impact des solutions de santé numériques sur la santé des populations africaines, afin d'identifier les succès et les défis, et d'orienter les efforts futurs.",
    },
    {
      title: "Partager l'Expérience",
      content:
        "Favoriser le partage des connaissances et des meilleures pratiques en matière de santé numérique à l'échelle du continent africain, en organisant des forums, des ateliers et en publiant des études de cas.",
    },
    {
      title: 'Conseiller et Accompagner',
      content:
        'Fournir des conseils et un accompagnement aux gouvernements, aux organisations de santé et aux acteurs du numérique pour le déploiement de solutions de santé numériques adaptées aux réalités africaines.',
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <Actualities />
    </main>
  );
};

export default NotreMission;
