import React from 'react';

const ContactInfo = () => {
  return (
    <div className=' mt-4 md:mt-0 py-4  md:py-6 md:px-6 bg-white '>
      <h2 className='text-xl font-bold mb-4 text-[#00075e]'>
        Information de contact
      </h2>
      <p className='mb-4'>
        Nous sommes ravis de l'intérêt que vous portez à nos initiatives en
        santé numérique. Que vous souhaitiez en savoir plus sur nos projets,
        participer à nos programmes de formation, ou simplement échanger avec
        notre équipe, nous sommes à votre disposition.
      </p>
      <h3 className='text-lg font-semibold text-[#00075e]'>Localisation</h3>
      <p className='mb-4'>Addresse: 61 Rue De Lyon, 75012 Paris</p>
      <p className='mb-4 flex space-x-2'>
        <span>Téléphone:</span>
        {'  '}
        <div className=' flex flex-col'>
          <span>+33 7 45 46 05 28</span>
          <span>+33 6 05 87 88 85</span>
        </div>{' '}
      </p>
      <p className='mb-4'>Email: contact@ceisna.org</p>
    </div>
  );
};

export default ContactInfo;
