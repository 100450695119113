import React from 'react';
import HeaderCarousel from '../componants/Home/HeaderCarousel';
import NotreMission from '../componants/Home/NotreMission';
import OurGroup from '../componants/Home/OurGroup';
import Actualities from '../componants/Home/Actualities';
import JoindUs from '../componants/Home/JoindUs';
import Services from '../componants/Home/Services';
import BackgroundVideo from '../componants/Home/BackgroundVideo';

const Home = () => {
  return (
    <main>
      {/* <HeaderCarousel /> */}
      <BackgroundVideo />
      <NotreMission />
      <OurGroup />
      <Services />
      <Actualities />
      <JoindUs />
    </main>
  );
};

export default Home;
