import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionContent from '../componants/OurMission/MissionContent';
import JoindUs from '../componants/Home/JoindUs';
import MissionThree from '../componants/utilities/MissionThree';
import banner from '../public/images/carousel_8.png';

const NosProjets = () => {
  const pageHeader = {
    title: 'Nos Projets',
    description:
      'Développement et mise en œuvre de programmes de santé communautaires pour améliorer la qualité de vie.',
    banner: banner,
  };

  const heroText = `Nos offres incluent l'évaluation de projets de santé numérique, le développement de stratégies numériques pour les organisations de santé, le conseil en sécurité des données médicales, et l'aide à la conformité réglementaire.  Ces services reflètent nos valeurs d'accessibilité, d'innovation, de collaboration, d'éthique, de renforcement des capacités et d'impact durable, visant à promouvoir une santé numérique inclusive et bénéfique pour l'Afrique.`;

  const listElements = {
    title: 'Développement de Stratégies en Santé Numérique:',
    enum: [
      'Promouvoir et garantir l’accès équitable aux solutions de santé numérique pour tous les individus.',
      'Réduire et faciliter l’élimination des disparités socio-économiques et géographiques dans l’accès aux soins.',
      "Encourager et assurer l'inclusion des populations marginalisées dans les initiatives de santé numérique.",
      "Faciliter l'accès aux soins pour tous.",
    ],
  };
  const listElements2 = {
    title: 'Innovation et Durabilité :',
    enum: [
      'Développer des technologies de santé numériques adaptées.',
      'Assurer et pérenniser la durabilité des solutions numériques mises en place pour le long terme.',
      'Intégrer et adapter les nouvelles technologies de manière à répondre aux défis spécifiques du secteur.',
      "Promouvoir et soutenir l'innovation continue pour améliorer les systèmes de santé numérique.",
    ],
  };

  const accordions = [
    {
      title: 'Évaluation de Projets de Santé Numérique :',
      content:
        "Nous offrons des services d'évaluation et d'analyse approfondie de projets de santé numérique. Les entreprises et les startups du secteur pourraient solliciter notre expertise pour évaluer la viabilité, l'efficacité et la conformité réglementaire de leurs initiatives.",
    },
    {
      title: 'Développement de Stratégies en Santé Numérique : ',
      content:
        'Nous souhaitons collaborer avec des organisations de santé pour élaborer des stratégies de mise en œuvre de solutions numériques dans leurs pratiques médicales, hôpitaux ou systèmes de soins de santé.',
    },
    {
      title: 'Formation en Santé Numérique',
      content:
        "Le CEiSNAE sera emmené à organiser des programmes de formation personnalisés pour les professionnels de la santé, les gestionnaires de données et les responsables informatiques sur des sujets tels que l'IA en santé, la télémédecine ou la gestion des dossiers de santé électroniques.",
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default NosProjets;
