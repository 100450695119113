import React from 'react';
import ContactHeader from '../componants/Contact/ContactHeader';
import ContactSection from '../componants/Contact/ContactSection';
import JoindUs from '../componants/Home/JoindUs';
import ContactMap from '../componants/Contact/ContactMap';

const ContactezNous = () => {
  return (
    <main>
      <ContactHeader />
      <ContactSection />
      {/* <JoindUs /> */}
      <ContactMap />
    </main>
  );
};

export default ContactezNous;
