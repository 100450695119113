import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/cooperations-entreprise.png';

const CooperationEntreprise = () => {
  const pageHeader = {
    title: 'Coopération Avec Les Entrerprises',
    description:
      "Promouvoir la collaboration avec les entreprises pour stimuler l'innovation, le développement et la croissance économique durable.",
    banner: banner,
  };

  const heroText = `La Commission Coopération avec les Entreprises du CEISNA se focalise sur la création de partenariats stratégiques avec des entreprises pour favoriser le développement et l'adoption de technologies de santé numérique en Afrique.`;

  const listElements = {
    title: 'Missions:',
    enum: [
      'Établir et renforcer des partenariats avec des entreprises technologiques et des startups.',
      'Identifier les besoins des entreprises et proposer des solutions adaptées.',
      'Organiser des forums et des rencontres pour favoriser les échanges entre les entreprises et les experts du CEISNA.',
      'Soutenir les entreprises dans le développement et la mise en œuvre de solutions de santé numérique.',
    ],
  };

  const listElements2 = {
    title: 'Propositions de Valeur:',
    enum: [
      "Accès à un réseau d'experts et de partenaires potentiels.",
      'Opportunités de collaboration et de co-développement.',
      'Soutien pour la mise sur le marché de nouvelles technologies de santé numérique.',
      'Visibilité et promotion des innovations auprès des décideurs et des parties prenantes.',
    ],
  };

  const accordions = [
    {
      title: ' Comment puis-je proposer un partenariat avec le CEISNA ?  ',
      content:
        'Vous pouvez soumettre une proposition de partenariat via notre portail en ligne ou nous contacter directement pour discuter des opportunités.',
    },
    {
      title: 'Quels types de partenariats sont possibles ?',
      content:
        'Nous proposons divers types de partenariats, y compris le co-développement, le financement de projets, et la promotion des innovations technologiques.',
    },
    {
      title:
        ' Y a-t-il des critères spécifiques pour les entreprises souhaitant collaborer avec le CEISNA ? ',
      content:
        'Les entreprises doivent démontrer un engagement envers la santé numérique et un intérêt pour le marché africain.',
    },
  ];
  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default CooperationEntreprise;
