import React from 'react';

const SwitchButton = ({ setIspersonel, isPersonel }) => {
  return (
    <div className=' w-[100%] flex justify-center mx-auto pb-2 '>
      <div className=' relative justify-center flex items-center h-[3rem] px-2 py-2 w-[100%] md:w-[80%]  2xl:w-[70%] shadow-lg bg-[#5bc199] rounded-full'>
        <div
          className={`${
            isPersonel ? ' right-[35%]' : '-right-[10%] '
          } z-0  min-w-[55%] text-[#fff] absolute Personnelles transition-all duration-700 ease-in-out`}
        >
          <span
            className={`${
              isPersonel ? ' opacity-0 hidden' : 'opacity-[1] block '
            } transition-all duration-700 ease-in-out text-[0.9rem]  sm:text-[1rem] `}
          >
            Personne physique
          </span>
          <span
            className={`${
              isPersonel ? 'opacity-[1] block' : 'opacity-0 hidden '
            } transition-all duration-700 ease-in-out text-[0.9rem]  sm:text-[1rem] `}
          >
            Personne Moral
          </span>
        </div>
        <button
          onClick={() => setIspersonel(!isPersonel)}
          className={`z-10 min-w-[65%] justify-center absolute flex px-2 rounded-full py-2 bg-[#fff] items-center transition-all duration-700 ease-in-out ${
            isPersonel
              ? ' right-[4px]  sm:right-[0.2rem] md:right-[5px]'
              : ' right-[33.5%]'
          }`}
        >
          <span
            className={`${
              isPersonel ? 'block opacity-[1] ' : 'hidden opacity-0'
            } transition-all duration-700 ease-in-out text-[0.9rem]  sm:text-[1rem]`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6  '
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18'
              />
            </svg>
          </span>
          <span
            className={`${
              isPersonel ? ' opacity-0 hidden' : 'opacity-[1] block '
            } transition-all duration-700 ease-in-out text-[0.9rem]  sm:text-[1rem] `}
          >
            Personne Moral
          </span>
          <span
            className={`${
              isPersonel ? 'opacity-[1] block' : 'opacity-0 hidden '
            } transition-all duration-700 ease-in-out text-[0.9rem]  sm:text-[1rem] `}
          >
            Personne physique
          </span>
          <span
            className={`${
              isPersonel ? 'hidden opacity-0' : 'block opacity-[1]'
            } transition-all duration-700 ease-in-out`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SwitchButton;
