import { useParams } from 'react-router-dom';
import NewContent from '../componants/News/NewContent';
import OtherNews from '../componants/News/OtherNews';
import { useDocument } from '../hooks/useDocument';
import { useCollection } from '../hooks/useCollection';
import NewHeader from '../componants/News/NewHeader';
import Spinner from '../componants/utilities/Spinner';

const NewDetails = () => {
  let { id } = useParams();

  const { docResponse } = useDocument('blogs', id);
  const { documents, loading, error } = useCollection('blogs');
  const { document } = docResponse;

  const docs = documents.filter((doc) => doc.id !== id);

  if (loading) {
    return (
      <div className=' items-center flex justify-center h-[70vh] w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
        <Spinner loading={loading} />
      </div>
    );
  }

  return (
    <main>
      <NewHeader document={document} />
      <NewContent loading={loading} document={document} />
      <OtherNews documents={docs} />
    </main>
  );
};

export default NewDetails;
