import bg from '../../public/images/b_1.png';

const MemberHeader = () => {
  return (
    <div
      style={{
        background: `linear-gradient(
        90deg,
        rgba(252, 252, 252, 0.807) 0%,
        rgba(255, 255, 255, 0.668) 25%,
        rgba(255, 255, 255, 0.031) 60%), url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className='h-[40vh] md:h-[60vh] w-full relative'
    >
      <div className=' px-2 lg:px-0 w-[100%] lg:w-[40%]  absolute top-[50%] lg:-translate-x-[-50%] -translate-y-[50%] lg:-left-[15%] '>
        <h2 className=' text-[1.2rem] md:text-[2rem] font-bold text-[#00075e]'>
          Intégrez la Communauté CEiSNA
        </h2>
        <p className=' text-[1rem] md:text-[1.2rem]'>
          Rejoignez Ceisna, la plateforme de commission dédiée aux spécialiste
          de santé pour collaborer et échanger.
        </p>
      </div>
    </div>
  );
};

export default MemberHeader;
