import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/commission-formation.png';

const CommeissionsFomation = () => {
  const pageHeader = {
    title: 'Commission fomation',
    description:
      'Les commissions de formation planifient, développent et supervisent des programmes éducatifs pour améliorer les compétences professionnelles.',
    banner: banner,
  };

  const heroText = `La Commission Formation du CEISNA vise à développer des programmes éducatifs et de formation pour les professionnels de santé, afin de promouvoir l'adoption et l'utilisation efficace des technologies de santé numérique en Afrique.`;

  const listElements = {
    title: 'Missions:',
    enum: [
      'Développer des programmes de formation adaptés aux besoins des professionnels de santé.',
      'Organiser des formations, séminaires et webinaires.',
      'Créer des supports pédagogiques et des ressources éducatives.',
      'Évaluer les besoins de formation en santé numérique.',
    ],
  };

  const listElements2 = {
    title: 'Propositions de Valeur :',
    enum: [
      'Formation de qualité adaptée aux besoins du marché.',
      'Accès à des experts de renommée internationale.',
      'Assurer la pérennité des solutions mises en place.',
      'Certification reconnue par le CEISNA.',
      'Opportunités de réseautage et de mentorat.',
    ],
  };

  const accordions = [
    {
      title: ' Quels sont les prérequis pour suivre une formation au CEISNA ? ',
      content:
        'Les prérequis varient selon les programmes, mais en général, une formation de base en santé ou en informatique est recommandée.',
    },
    {
      title: 'Les formations sont-elles certifiées ?',
      content: 'Oui, toutes nos formations sont certifiées par le CEISNA.',
    },
    {
      title: ' Les formations sont-elles disponibles en ligne ?',
      content:
        'Oui, nous offrons des formations en mode hybride, combinant des sessions en ligne et en personne.',
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default CommeissionsFomation;
