import React from 'react';

const NewHeader = ({ document }) => {
  return (
    <div
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${document?.image})`,
        height: '40vh',
        backgroundRepeat: 'repeat',
        width: '100vw',
      }}
    >
      <div className=' h-full flex items-center justify-center'>
        <h4 className=' font-semibold uppercase text-white text-[1.8rem]'>
          {document?.category}
        </h4>
      </div>
    </div>
  );
};

export default NewHeader;
