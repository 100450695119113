import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/sante-numerique.png';

const CommissionEthiqueEtDeontologie = () => {
  const pageHeader = {
    title: 'Commission éthique et déontologie',
    description:
      'Les commissions santé numérique développent, évaluent et mettent en œuvre des technologies pour améliorer les soins de santé.',
    banner: banner,
  };

  const heroText = `La Commission Éthique et Déontologie du CEISNA est chargée de veiller à l'intégrité et à la responsabilité dans le domaine de la santé numérique en Afrique. Cette commission établit et supervise les normes éthiques et déontologiques applicables à toutes les initiatives et projets menés sous l'égide du CEISNA. Elle s'assure que les pratiques respectent les principes de confidentialité, de sécurité des données et de respect des droits des patients.`;

  const listElements = {
    title: 'Missions de la Commission Éthique et Déontologie du CEISNA:',
    enum: [
      "Élaboration de Normes : Définir et promouvoir des standards éthiques pour l'utilisation des technologies numériques en santé.",
      'Supervision des Pratiques : Veiller à ce que toutes les activités du CEISNA soient conformes aux normes éthiques établies.',
      'Conseil et Orientation : Fournir des conseils sur les questions éthiques et déontologiques aux membres du CEISNA et à ses partenaires.',
      'Formation et Sensibilisation : Organiser des sessions de formation pour sensibiliser les professionnels de santé aux enjeux éthiques de la santé numérique.',
      'Évaluation et Audits : Mener des évaluations régulières et des audits des pratiques pour garantir le respect des standards éthiques.',
    ],
  };

  const listElements2 = {
    title: 'Valeurs de la Commission Éthique et Déontologie du CEISNA:',
    enum: [
      "Intégrité : Maintenir les plus hauts niveaux de transparence et d'honnêteté dans toutes les actions et décisions.",
      'Responsabilité : Assumer la responsabilité des impacts éthiques et déontologiques des technologies numériques en santé.',
      'Respect des Droits des Patients : Garantir la confidentialité, la sécurité et le respect des droits des patients dans toutes les initiatives.',
      "Innovation Responsable : Encourager l'innovation tout en veillant à ce qu'elle respecte les normes éthiques et déontologiques.",
      'Collaboration : Travailler en étroite collaboration avec les parties prenantes pour promouvoir des pratiques éthiques dans le domaine de la santé numérique.',
    ],
  };

  const accordions = [
    {
      title:
        "Quels sont les principaux standards éthiques et déontologiques que la Commission Éthique et Déontologie a mis en place pour encadrer l'utilisation des technologies numériques en santé au sein du CEISNA ?",
      content:
        " La Commission Éthique et Déontologie a établi plusieurs standards clés pour encadrer l'utilisation des technologies numériques en santé. Ces standards incluent la protection de la confidentialité des patients, la sécurité des données, le consentement éclairé, la transparence dans la collecte et l'utilisation des données, et l'équité dans l'accès aux technologies de santé numérique. Nous veillons également à ce que toutes les innovations respectent les droits humains fondamentaux et soient utilisées de manière à promouvoir le bien-être des patients.",
    },
    {
      title:
        'Quelles mesures sont prises pour garantir la confidentialité et la sécurité des données des patients dans les projets de santé numérique supervisés par le CEISNA ?',
      content:
        "Pour garantir la confidentialité et la sécurité des données des patients, nous utilisons des protocoles de cryptage avancés, des systèmes de gestion des accès stricts et des audits réguliers de nos infrastructures technologiques. Nous veillons à ce que toutes les données soient anonymisées lorsqu'elles sont utilisées à des fins de recherche et que seules les personnes autorisées aient accès aux informations sensibles. De plus, nous suivons les meilleures pratiques internationales en matière de protection des données et respectons les réglementations locales et internationales.",
    },
    {
      title:
        'Comment la Commission Éthique et Déontologie du CEISNA assure-t-elle la formation continue et la sensibilisation des professionnels de santé aux enjeux éthiques de la santé numérique ?',
      content:
        'La Commission Éthique et Déontologie organise régulièrement des ateliers, des séminaires et des webinaires pour former et sensibiliser les professionnels de santé aux enjeux éthiques de la santé numérique. Nous offrons également des cours en ligne certifiants qui couvrent les principes éthiques fondamentaux, les lois et réglementations pertinentes, ainsi que les bonnes pratiques en matière de gestion des données de santé. En outre, nous encourageons les échanges et discussions au sein de la communauté des professionnels de santé pour partager les expériences et les meilleures pratiques.',
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />

      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default CommissionEthiqueEtDeontologie;
