import { useNavigate } from 'react-router-dom';
import img from '../../../src/public/equipe/resp.jpeg';

const Respo = () => {
  const navigate = useNavigate();
  return (
    <section className=' py-6'>
      <div className=' space-x-5 flex-col md:flex-row flex w-[95%] md:w-[70%] xl:w-[60%] 2xl:w-[50%] mx-auto'>
        <div className=' w-full md:w-[40%]'>
          <div className=' w-full h-[450px]'>
            <img
              src={img}
              alt='image'
              className=' rounded-md h-full w-full object-cover'
            />
          </div>
        </div>
        <div className=' flex flex-col w-full md:flex-1'>
          <h1 className='mb-2 text-[1.2rem] font-semibold'>Présidente</h1>
          <h4 className=' font-bold mb-1 text-[#00075e] text-[1.2rem]'>
            Mme BEYALA LAURE
          </h4>
          <blockquote className='border-l-4 border-blue-500 pl-4 text-gray-700 italic mb-4'>
            <span>
              Le CEISNA est dédié à l'amélioration de la santé en Afrique grâce
              à l'innovation numérique. Nous croyons fermement que les nouvelles
              technologies peuvent transformer les systèmes de santé, rendre les
              soins plus accessibles et améliorer la qualité de vie de millions
              de personnes.
            </span>{' '}
            <br />
            Notre mission est claire : créer un écosystème vibrant où les
            professionnels de la santé, les chercheurs, les entrepreneurs et les
            décideurs peuvent collaborer, échanger des idées et développer des
            solutions innovantes face aux défis de santé les plus pressants du
            continent. Nous nous engageons à offrir des programmes de formation
            de haute qualité, à promouvoir la recherche et à soutenir les
            projets novateurs ayant le potentiel de faire une réelle différence.
            Votre participation active et votre soutien sont essentiels pour
            atteindre ces objectifs ambitieux.
          </blockquote>
          <div className='  mt-1 flex space-x-4'>
            <button
              onClick={() => navigate('/contactez-nous')}
              className=' bg-[#00075e] rounded-md text-white px-4 py-2'
            >
              Contactez nous
            </button>
            <button
              onClick={() => navigate('/experts')}
              className=' rounded-md border-[1px]  border-[#00075e] px-6 py-2'
            >
              Expert
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Respo;
