import React from 'react';
import ListDestails from './ListDestails';
import Accordions from './Accordions';

const MissionContent = ({ accordions, listElements, listElements2 }) => {
  return (
    <div className=' py-10'>
      <div>
        <div className=' w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
          <ListDestails listElements={listElements} />
          <ListDestails listElements={listElements2} mt={10} />
        </div>
        <Accordions accordions={accordions} />
      </div>
    </div>
  );
};

export default MissionContent;
