import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix the default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const ContactMap = () => {
  return (
    <div>
      <MapContainer
        center={[48.85056, 2.37013]}
        zoom={13}
        // style={{ height: '500px', width: '100%' }}
        className=' h-[350px] md:h-[500px] w-full'
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributeurs'
        />
        <Marker position={[48.85056, 2.37013]}>
          <Popup>
            Bureau Healthtest <br /> Dhaka, Bangladesh.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default ContactMap;
