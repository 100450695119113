import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/carousel_3.png';
import Evenements from '../componants/Events/Evenements';
import { useCollection } from '../hooks/useCollection';

const Events = () => {
  const pageHeader = {
    title: 'Evènements',
    description: `Rencontres clés pour célébrations, formations, discussions, et partages d'informations entre individus.`,
    banner: banner,
  };

  const { documents, loading, error } = useCollection('events');

  // console.log('=============', documents);

  return (
    <main>
      <PageHeader w={'55%'} pageHeader={pageHeader} />
      <Evenements docs={documents} />
    </main>
  );
};

export default Events;
