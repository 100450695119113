import React from 'react';
import { Link } from 'react-router-dom';
import gaol from './../../public/images/gaol.svg';
import team from './../../public/images/team.svg';

const CommissionsSection = () => {
  const commissions = [
    {
      title: 'Commission Coopération Internationale ',
      url: '/commissions-sante',
      svg: gaol,
      backgroundColor: '#cbf0eee7',
      hoverColor: '#a6e0dc',
      description:
        'La Commission Coopération Internationale du CEISNA promeut la collaboration internationale pour développer la santé numérique en Afrique via des partenariats stratégiques.',
    },
    {
      title: 'Commissions Recherche',
      url: '/commissions-recherche',
      svg: team,
      backgroundColor: '#00c5ccb9',
      hoverColor: '#00c4cc',
      description:
        'Les commissions de recherche supervisent, évaluent et encouragent des projets innovants pour avancer la connaissance scientifique.',
    },
    {
      title: 'Commissions fomation',
      url: '/commissions-fomation',
      svg: team,
      backgroundColor: '#cce2ed',
      hoverColor: '#a6cde0',
      description:
        'Les commissions de formation planifient, développent et supervisent des programmes éducatifs pour améliorer les compétences professionnelles.',
    },

    {
      title: 'Commission éthique et déontologie',
      url: '/commissions-sante-numerique',
      svg: team,
      backgroundColor: '#e2e5f0',
      hoverColor: '#c0c7df',
      description:
        "La Commission Éthique du CEISNA veille à l'intégrité en santé numérique en Afrique, établissant et supervisant des normes éthiques et déontologiques.",
    },
    {
      title: 'Groupe Consultatif',
      url: '/groupe-consultatif',
      svg: team,
      backgroundColor: '#94b9ffbc',
      hoverColor: '#94b9ff',
      description:
        'Le Groupe consultatif sur les affaires réglementaires fournit des conseils sur les politiques et la conformité réglementaire.',
    },
    {
      title: 'Coopération Avec Les Entrerprises',
      url: '/cooperation-entreprise',
      svg: team,
      // backgroundColor: '#b9e5ff',
      // hoverColor: '#e5abc9',
      backgroundColor: '#38b6ff6f',
      hoverColor: '#38b6ff',
      description:
        "Promouvoir la collaboration avec les entreprises pour stimuler l'innovation, le développement et la croissance économique durable.",
    },
  ];
  return (
    <div className=''>
      <div className='   w-[95%] md:w-[80%] py-10 mx-auto'>
        <div>
          <div className=' gap-4 md:gap-8 grid notremission__items'>
            {commissions.map((commission) => (
              <Link
                to={commission.url}
                className={` overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] duration-300 transition-all hover:scale-[1.02] hover:bg-[${commission.hoverColor}] bg-[${commission.backgroundColor}]`}
              >
                {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
                <div className=' rounded h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                  <img src={gaol} alt='notre mission' className='h-full ' />
                </div>

                <h3 className='   text-[1.1rem] md:text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                  {commission.title}
                </h3>
                <p>{commission.description}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionsSection;
