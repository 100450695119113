import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EventsCard from './EventsCard';

const RapportEvenement = ({ docs }) => {
  const [newsLength, setNewsLength] = useState(6);
  let events = docs.slice(0, newsLength);

  const isAllLoaded = newsLength >= docs.length;
  events = events.filter((event) => event.type === 'Passe');
  const detailsUrl = '/rapport-evenment/';

  return (
    <main className=' py-10 bg-[#ece9e5]'>
      <div className='w-[55%] mx-auto'>
        {events.length === 0 && (
          <div>
            <h4>Aucun evenements disponible</h4>
          </div>
        )}
        <ul className=' flex flex-col space-y-6 '>
          {events.map((event, index) => (
            <EventsCard event={event} index={index} detailsUrl={detailsUrl} />
          ))}
        </ul>

        {events.length > 0 && (
          <div className=' py-4 flex justify-center items-center'>
            <button
              onClick={() => setNewsLength(newsLength + 6)}
              disabled={isAllLoaded}
              className={` hover:bg-[#d0407c] text-[#fff] rounded-full py-2 border-[#ed4f91] bg-[#ed4f91] border-[1px] px-5 ${
                isAllLoaded ? 'cursor-not-allowed opacity-50' : ''
              }`}
            >
              Charger plus
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default RapportEvenement;
