import eq_1 from './../../public/equipe/eq_1.jpeg';
import eq_2 from './../../public/equipe/eq_2.jpeg';
import eq_3 from './../../public/equipe/equipe_3.jpeg';
import com_1 from './../../public/equipe/eq_3.jpeg';
import com_2 from './../../public/equipe/eq_4.jpeg';
import com_5 from './../../public/equipe/eq_5.jpeg';

const OurTeam = () => {
  return (
    <section className=' py-7  bg-white'>
      <div className='w-[90%] md:w-[70%] xl:w-[60%] 2xl:w-[50%] mx-auto'>
        <div className='mb-2 w-full flex-col flex justify-center items-center'>
          <h2 className=' text-[1.8rem] text-[#00075e] font-semibold'>
            Equipe bureau
          </h2>
          <p>Présentation de notre équipe de bureau.</p>
        </div>
        <div className=' gap-5 py-2 grid  md:grid-cols-3 grid-cols-1'>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={eq_1}
                className=' w-[200px] object-cover border-[1px] border-[#00075e] rounded-full h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                Dr ISSA HAROUNA
              </span>
            </div>
          </div>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={eq_2}
                className=' border-[1px] border-[#00075e] rounded-full w-[200px] h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                M. Mamadou Dia
              </span>
            </div>
          </div>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={eq_3}
                className=' object-cover border-[1px] border-[#00075e] rounded-full w-[200px] h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                Dr Bato Bagnan
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      {/* <div className='w-[90%] mt-8 md:w-[70%] xl:w-[60%] 2xl:w-[50%] mx-auto'>
        <div className='mb-2 w-full flex-col flex justify-center items-center'>
          <h2 className='text-[1.8rem] text-[#00075e] font-semibold'>
            Equipe Communication
          </h2>
          <p>Découvrez notre équipe dédiée à la communication.</p>
        </div>
        <div className=' gap-5 py-2 grid md:grid-cols-3 grid-cols-1'>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={com_1}
                className=' w-[200px] object-cover border-[1px] border-[#00075e] rounded-full h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                M. Tidiane Bamadio
              </span>
            </div>
          </div>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={com_2}
                className=' border-[1px] border-[#00075e] rounded-full w-[200px] h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                M. Cleophas Kwembe
              </span>
            </div>
          </div>
          <div className=' py-4 flex items-center justify-center rounded-lg shadow-md bg-[#f9f9f9]'>
            <div>
              <img
                src={com_5}
                className=' object-cover border-[1px] border-[#00075e] rounded-full w-[200px] h-[200px]'
                alt='image'
              />
              <span className=' block w-full text-center text-gray-600 mt-1 text-[1.1rem]'>
                Suzanne Gualbert
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default OurTeam;
