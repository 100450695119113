import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: '#ffffff',
};

const ButtonSpinner = ({ loading }) => {
  let [color] = useState('#ffffff');
  return (
    <ClipLoader
      color={color}
      loading={loading}
      cssOverride={override}
      size={50}
      aria-label='Loading Spinner'
      data-testid='loader'
    />
  );
};

export default ButtonSpinner;
