import React from 'react';
import { Link } from 'react-router-dom';
import gaol from './../../public/images/gaol.svg';
import value from './../../public/images/values.svg';
import team from './../../public/images/team.svg';
import project from './../../public/images/ambulance.svg';
import impact from './../../public/images/project.svg';
import contact from './../../public/images/contact.svg';
import HeaderTitle from '../../utilities/HeaderTitle';

const MissionThree = () => {
  return (
    <div className=''>
      <div className=' w-[80%] py-10 mx-auto'>
        <div>
          <div className=' gap-8 grid notremission__items'>
            <Link
              to={'/notre-mission'}
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] duration-300 transition-all hover:scale-[1.02] hover:bg-[#a6e0dc] bg-[#cbf0eee7]'
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={gaol} alt='notre mission' className='h-full ' />
              </div>

              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Mission
              </h3>
              <p>
                Améliorer la santé publique en promouvant des initiatives et
                politiques de soins de santé efficaces.
              </p>
            </Link>

            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] hover:scale-[1.02]  duration-300 transition-all hover:bg-[#f8cfaf] bg-[#fcebde]'
              to={'/notre-equipe'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={team} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Équipe
              </h3>
              <p>
                Une équipe dédiée de professionnels de santé, d'experts et de
                chercheurs passionnés par le bien-être.
              </p>
            </Link>
            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] hover:scale-[1.02] duration-300 transition-all hover:bg-[#c0c7df] bg-[#e2e5f0]'
              to={'/notre-impact'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={impact} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Impact
              </h3>
              <p>
                Transformations significatives dans les communautés grâce à nos
                initiatives de santé et de prévention.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionThree;
