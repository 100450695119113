import { useState } from 'react';

const QR = [
  {
    question: 'Recherche et Innovation',
    response:
      'Notre équipe est fière de partager une vaste expertise dans la recherche en santé numérique. Nous menons des recherches pionnières pour identifier les meilleures pratiques et les technologies émergentes afin de les intégrer dans nos projets.',
  },
  {
    question: 'Consultation et Formation',
    response:
      'Nous offrons des services de consultation et de formation pour renforcer les capacités locales. Nos programmes de formation sont conçus pour améliorer les compétences des professionnels de santé et des techniciens en matière de santé numérique.',
  },

  {
    question: 'Coopération Internationale',
    response:
      'Nous facilitons la coopération internationale en établissant des partenariats avec des organisations mondiales, des agences de santé et des entreprises technologiques. Cette collaboration permet de partager les meilleures pratiques et de mobiliser des ressources pour des projets communs.',
  },
  {
    question: 'Développement de Politiques et Réglementations',
    response:
      "Nous assistons les gouvernements et les organisations dans l'élaboration de politiques et de réglementations en matière de santé numérique. Notre objectif est de créer un cadre réglementaire favorable à l'innovation tout en assurant la protection des données et la sécurité des patients.",
  },
];

const Services = () => {
  const [openQuestions, setOpenQuestions] = useState();

  return (
    <div className='bg-[#00075e] border-b-2 border-b-white'>
      <div className=' gap-8 md:gap-4  lg:gap-16 w-[95%] md:w-[80%] mx-auto text-[#fff] grid grid-cols-1  lg:grid-cols-3 mt-5 py-10 '>
        <div>
          <h2 className=' uppercase font-semibold text-[1.4rem]'>Services</h2>
          <p className=' text-[1.3rem] md:text-[1.6rem] mt-5'>
            Nous utilisons notre expertise collective pour répondre aux besoins
            des partenaires, superviser les solutions et assurer leur
            durabilité.
          </p>
        </div>
        <div>
          <ul>
            <li>
              <div className='mt-3 mb-4 text-[1.3rem] md:text-[1.6rem]'>
                <span>0.1</span>
                <span> Accompagnement et Co-conception des solutions</span>
              </div>

              <p>
                Chaque projet nécessite une approche sur mesure. Nous concevons
                des solutions innovantes, en collaborant étroitement avec nos
                partenaires, pour répondre à leurs besoins spécifiques. Il
                n'existe pas de stratégie unique pour tous.
              </p>
            </li>
            <li>
              <div className='mt-3 mb-4 text-[1.3rem] md:text-[1.6rem]'>
                <span>02.</span>
                <span> Évaluation et Validation</span>
              </div>

              <p>
                Nous validons l'efficacité des technologies en santé numérique
                par des évaluations rigoureuses, utilisant des méthodologies
                basées sur des standards internationaux, adaptées aux réalités
                africaines.
              </p>
            </li>
            <li>
              <div className=' mt-2 mb-4 text-[1.3rem] md:text-[1.6rem]'>
                <span>03.</span>
                <span>Développement de Politiques et Réglementations</span>
              </div>

              <p>
                Nous aidons les gouvernements et organisations à élaborer des
                politiques de santé numérique, favorisant l'innovation tout en
                protégeant les données et assurant la sécurité des patients.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <ul className=' border-b-[1px] border-b-[#ffffff9e] flex flex-col'>
            {QR.map((item, i) => (
              <li
                key={i}
                className=' overflow-hidden border-t-[1px] mb-2 border-t-[#ffffff9e]  py-4 '
              >
                <div className='flex space-x-2 justify-between'>
                  <span className=' text-[1.3rem] md:text-[1.5rem]'>
                    {item.question}
                  </span>
                  <button
                    onClick={() =>
                      setOpenQuestions(openQuestions === i ? '' : i)
                    }
                    className=''
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      fill='currentColor'
                      className={` h-6 w-6 transition-all duration-500 ${
                        openQuestions === i ? 'rotate-45' : ''
                      }`}
                    >
                      <path
                        fillRule='evenodd'
                        d='M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
                <p
                  className={`${
                    openQuestions === i
                      ? 'translate-y-0 duration-500 transition-all  '
                      : '-translate-y-36 duration-500 transition-all  absolute top-0  '
                  } transition-all duration-500     py-3`}
                >
                  {item.response}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
