import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionContent from '../componants/OurMission/MissionContent';
import JoindUs from '../componants/Home/JoindUs';
import Actualities from '../componants/Home/Actualities';
import banner from '../public/images/carousel_8.png';

const NotreImpact = () => {
  const pageHeader = {
    title: 'Notre impact',
    description:
      'Transformations significatives dans les communautés grâce à nos initiatives de santé et de prévention.',
    banner: banner,
  };

  const heroText = `Le Comité d’Experts Internationaux en Santé Numérique pour l’Afrique (CEISNA) a eu un impact profond et transformationnel sur le secteur de la santé en Afrique. En intégrant des technologies de pointe telles que l'intelligence artificielle le le big data,  le CEISNA a permet une modernisation rapide des infrastructures sanitaires du continent.`;

  const listElements = {
    title: "Amélioration de l'Accès aux Soins de Santé:",
    enum: [
      "Faciliter l'accès aux consultations médicales ",
      'Réduire les disparités géographiques et socio-économiques',
      'Renforcer la résilience des systèmes de santé africains.',
    ],
  };
  const listElements2 = {
    title: 'Modernisation des Systèmes de Gestion des Données de Santé:',
    enum: [
      "Promouvoir l'adoption des technologies numériques.",
      "Assurer la standardisation et l'interopérabilité des données de santé.",
      'Utiliser des algorithmes avancés pour prédire et prévenir les maladies.',
    ],
  };

  const accordions = [
    {
      title: 'Amélioration de la Qualité des Soins',
      content:
        "Implémenter des systèmes de gestion de la qualité qui utilisent des données numériques pour identifier et corriger les lacunes dans les soins de santé.Évaluer en continu les performances des professionnels de santé et des établissements de soins à l'aide de métriques numériques. Optimiser les processus cliniques pour réduire les erreurs médicales et améliorer les résultats pour les patients grâce à des outils numériques avancés.",
    },
    {
      title: 'Innovation et Recherche en Santé Numérique ',
      content:
        "Développer des projets de recherche innovants utilisant l'intelligence artificielle et le big data pour résoudre des problèmes de santé publique. Collaborer avec des institutions universitaires et de recherche pour explorer de nouvelles solutions numériques en santé. Publier des résultats de recherche pour partager les découvertes et les innovations avec la communauté scientifique mondiale.",
    },
    {
      title: 'Collaboration et Partenariats Stratégiques',
      content:
        'Établir des partenariats avec des organisations locales et internationales pour renforcer les initiatives en santé numérique. Coopérer avec les gouvernements et les institutions de santé pour intégrer les solutions numériques dans les politiques de santé publique. Mobiliser des ressources et des financements pour soutenir les projets de santé numérique à grande échelle.',
    },
  ];
  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <Actualities />
    </main>
  );
};

export default NotreImpact;
