import React from 'react';

const Copyright = () => {
  return (
    <div className='bg-[#003f97] text-white py-4'>
      <div className='container mx-auto text-center'>
        <p>&copy; {new Date().getFullYear()} CEISNA. Tous droits réservés.</p>
      </div>
    </div>
  );
};

export default Copyright;
