import React from 'react';
import JoindUs from '../componants/Home/JoindUs';
import { useDocument } from '../hooks/useDocument';
import { useParams } from 'react-router-dom';

const DetailsRapports = () => {
  let { id } = useParams();

  const getDate = (date) => {
    const dateObject = new Date(date);

    const months = [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ];

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const { docResponse } = useDocument('events', id);
  const { document } = docResponse;

  return (
    <main className=' '>
      <div className=' w-[95%] py-10 md:w-[50%] mx-auto'>
        <h1 className=' text-[#00075e] text-[1.2rem] sm:text-[1.8rem] font-semibold'>
          {document?.title}
        </h1>
        <div className=' h-[400px] sm:h-[500px] w-full'>
          <img
            className=' rounded-md w-full h-full object-cover'
            src={document?.image}
            alt=''
          />
        </div>
        <div className=' sm:space-x-8 py-1 resume flex-col md:flex-row  flex'>
          <div className=' w-full  sm:flex-1'>
            <h3 className=' pt-4  font-semibold text-[1.1rem]'>Résumé</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: document?.description,
              }}
            />
          </div>
          <div className=' w-full space-y-2 mt-2 sm:w-[20%]'>
            <div className='border-b-2 pb-2'>
              <div className=' flex space-x-1'>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='size-6 text-[#d0407c]'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                    />
                  </svg>
                </span>
                <h2 className=' font-semibold'>Date</h2>
              </div>
              <div className=' inline-block ml-6'>
                <span> {getDate(document?.startDate?.toDate())} </span>
              </div>
            </div>
            <div className='border-b-2 pb-2'>
              <div className='  flex space-x-1'>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='size-6 text-[#d0407c]'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                    />
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
                    />
                  </svg>
                </span>
                <h2 className='font-semibold'>Localité</h2>
              </div>
              <div className=' inline-block ml-6'>
                <span>{document?.location} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JoindUs />
    </main>
  );
};

export default DetailsRapports;
