const Pagination = ({
  documents,
  productsPerPage,
  currentPage,
  paginate,
  indexOfLastProduct,
}) => {
  return (
    <div className='flex justify-center space-x-2 py-4'>
      <button
        disabled={currentPage === 1}
        onClick={() => paginate(currentPage - 1)}
        className=' py-1  rounded'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className=' h-4 w-4'
        >
          <path
            fillRule='evenodd'
            d='M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z'
            clipRule='evenodd'
          />
        </svg>
      </button>
      {Array.from({
        length: Math.ceil(documents.length / productsPerPage),
      }).map((_, index) => (
        <button
          key={index + 1}
          onClick={() => paginate(index + 1)}
          className={`px-3 py-1 ${
            index + 1 === currentPage ? 'bg-[#003f97] text-white' : ''
          } rounded`}
        >
          {index + 1}
        </button>
      ))}
      <button
        disabled={indexOfLastProduct >= documents.length}
        onClick={() => paginate(currentPage + 1)}
        className=' py-1  rounded'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className=' h-4 w-4'
        >
          <path
            fillRule='evenodd'
            d='M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
