import finger from '../../public/images/hand.svg';

const ListDestails = ({ mt, listElements }) => {
  return (
    <ul className={` mt-${mt} flex-col flex space-y-5`}>
      <h5>{listElements?.title}</h5>
      {listElements?.enum?.map((el, i) => (
        <li className=' ml-5 space-x-2 flex items-start'>
          <img src={finger} className=' h-[20px]' alt='' />
          <span>{el} </span>
        </li>
      ))}
    </ul>
  );
};

export default ListDestails;
