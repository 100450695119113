import React from 'react';
import TopMenu from '../componants/Header/TopMenu';
import HeaderMenu from '../componants/Header/HeaderMenu';

export const Header = () => {
  return (
    <header id='header'>
      <TopMenu />
      <HeaderMenu />
    </header>
  );
};
