import React from 'react';

const HeaderTitle = ({ children }) => {
  return (
    <h2 className=' text-[1.5rem] md:text-[2.5rem] font-bold text-[#00075e] '>
      {children}
    </h2>
  );
};

export default HeaderTitle;
