import { Link } from 'react-router-dom';

const NewsCard = ({ newsItem, index }) => {
  const sanitizeDescription = (htmlString) => {
    return htmlString.replace(/"/g, '');
  };

  // const description = event.description.length > 300
  //   ? event.description.substring(0, 300) + '...'
  //   : event.description;

  return (
    <li
      key={index}
      className=' hover:scale-[1.02]  shadow-lg rounded-[20px] transition-all duration-500 bg-[#F0FCFA]'
    >
      <Link to={`/actualites/details/${newsItem.id}`} className=''>
        <div className=' h-[250px] relative overflow-hidden'>
          <img
            style={{ borderRadius: '16px 16px 100px 0px' }}
            className=' w-full    h-full object-cover'
            src={newsItem.image}
            alt=''
          />
          <div className=' w-[350px] text-[0.8em]  py-[0.10rem] flex justify-center rotate-45 px-6 text-white uppercase bg-[#0658ca] top-12 -right-[5rem] absolute  z-30 text-[]'>
            <span>{newsItem.category} </span>
          </div>
        </div>
        <div className=' pt-6 pb-3 px-4'>
          <h3 className=' text-[1.05rem] mb-3 text-[#00075e] font-semibold'>
            {newsItem.title.length > 70
              ? newsItem.title.substring(0, 70) + '...'
              : newsItem.title}
          </h3>
          {/* <p
            dangerouslySetInnerHTML={{
              __html:
                newsItem.description.length > 100
                  ? newsItem.description.substring(0, 100) + '...'
                  : newsItem.description,
            }}
          /> */}
          <div
            className=' text-[0.9rem]'
            dangerouslySetInnerHTML={{
              __html:
                newsItem.description.length > 100
                  ? String(newsItem.description.substring(0, 100)) + '...'
                  : newsItem.description,
            }}
          />
        </div>
        <div className=' pb-5 justify-center flex items-center'>
          <div>
            <button className=' items-center flex space-x-2 rounded-full  pl-4 bg-[#00075e] text-white'>
              <span>Lire plus</span>

              <span className=' inline-block p-2 rounded-full bg-[#520497]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='size-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NewsCard;
