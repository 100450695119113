import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';

const MeetingsList = ({ meetings }) => {
  return (
    <div className=' mx-auto w-[370px] sm:w-[600px] md:w-full  overflow-x-auto'>
      <table className='min-w-full bg-white'>
        <thead>
          <tr className='border-b-[1px] border-b-gray-300'>
            <th className='py-2 px-4 w-1/6 text-start'>Date</th>
            <th className='py-2 px-4 w-2/6 text-start'>
              Groupe d'experts/sous-groupe
            </th>
            <th className='py-2 px-4 w-1/6 text-start'>Groupe Consultatif</th>
            {/* <th className='py-2 px-4 w-2/6 text-start'>Details</th> */}
          </tr>
        </thead>
        <tbody>
          {meetings.map((meeting, index) => (
            <tr
              key={index}
              className=' border-b-[1px] border-b-gray-300 text-start'
            >
              <td className='py-2 px-4 whitespace-normal text-[0.9rem] '>
                {formatDate(Number(meeting?.startDate?.seconds))}
              </td>
              <td className='py-2 px-4 whitespace-normal text-[0.9rem] '>
                {meeting.group}
              </td>
              <td className='py-2 px-4 whitespace-normal text-[0.9rem]'>
                {meeting.type}
              </td>
              {/* <td className='py-2 px-4 whitespace-normal text-[0.9rem]'>
                <Link
                  to={'/ressource/details/' + meeting.id}
                  className='text-[#003f97] hover:underline'
                >
                  Voir details
                </Link>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MeetingsList;
