import React from 'react';
import HeaderTitle from '../../utilities/HeaderTitle';
import { Link, useNavigate } from 'react-router-dom';
import { news } from '../../data/news';
import NewsCard from '../News/NewsCard';
import { useCollection } from '../../hooks/useCollection';

const Actualities = () => {
  const navigate = useNavigate();
  const { documents, loading, error } = useCollection('blogs');

  return (
    <div className=' py-10'>
      <div className=' w-[95%] md:w-[80%] mx-auto'>
        <HeaderTitle>Actualités et annonces</HeaderTitle>
        <ul className=' gap-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
          {documents
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 3)
            .map((newsItem, index) => (
              <NewsCard newsItem={newsItem} index={index} />
            ))}
        </ul>
        <div className=' mt-4 flex items-center justify-center'>
          <button
            onClick={() => navigate('/actualites')}
            className=' border-[1px] px-6 py-2 rounded-[50px] bg-transparent border-[#00075e] text-[#00075e]'
          >
            Voir Tous
          </button>
        </div>
      </div>
    </div>
  );
};

export default Actualities;
