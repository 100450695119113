import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionContent from '../componants/OurMission/MissionContent';
import JoindUs from '../componants/Home/JoindUs';
import Actualities from '../componants/Home/Actualities';
import banner from '../public/images/carousel_8.png';
import MissionThree from '../componants/utilities/MissionThree';
import AboutContent from '../componants/aboutUs/AboutContent';

const AboutUs = () => {
  const pageHeader = {
    title: 'A propos de nous',
    description: '',
    banner: banner,
  };

  const heroText =
    "Le Comité d'Experts Internationaux en Santé Numérique pour l'Afrique (CEISNA) est crucial pour moderniser les systèmes de santé africains, en intégrant des technologies numériques pour améliorer les soins et surmonter divers défis.";
  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <AboutContent />
      <MissionThree />
      <JoindUs />
      <Actualities />
    </main>
  );
};

export default AboutUs;
