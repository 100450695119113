import React from 'react';

const SearchFilters = ({
  startDate,
  endDate,
  search,
  group,
  setStartDate,
  setGroup,
  setSearch,
  setEndDate,
}) => {
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFilters({
  //     ...filters,
  //     [name]: value,
  //   });
  // };

  const resetHandler = () => {
    setEndDate(null);
    setStartDate(null);
    setSearch('');
    setGroup('');
  };

  return (
    <div className='p-4  rounded-lg  space-y-4'>
      <div className='mb-1'>
        <label className='text-[0.9rem] font-semibold' htmlFor='date'>
          Du
        </label>
        <input
          type='date'
          name='startDate'
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className='w-full p-2  border rounded'
          placeholder='Du'
        />
      </div>
      <div className='mb-1'>
        <label className='text-[0.9rem] font-semibold' htmlFor='date'>
          Au
        </label>
        <input
          type='date'
          name='endDate'
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className='w-full p-2 border rounded'
          placeholder='Au'
        />
      </div>
      <div className='mb-1'>
        <label className='text-[0.9rem] font-semibold' htmlFor='date'>
          Titre
        </label>
        <input
          type='text'
          name='title'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='w-full p-2 border rounded'
          placeholder='Titre'
        />
      </div>
      <div className='mb-1'>
        <label className=' text-[0.9rem] font-semibold' htmlFor='date'>
          Nom du groupe
        </label>
        <input
          type='text'
          name='groupName'
          value={group}
          onChange={(e) => setGroup(e.target.value)}
          className='w-full p-2 border rounded'
          placeholder='Nom du groupe'
        />
      </div>

      {/* <div className='flex items-center'>
        <input
          type='checkbox'
          name='includeClosedGroups'
          checked={filters.includeClosedGroups}
          onChange={(e) =>
            setFilters({
              ...filters,
              includeClosedGroups: e.target.checked,
            })
          }
          className='mr-2'
        />
        <label>Inclure les réunions des groupes clôturés</label>
      </div> */}
      <button
        onClick={resetHandler}
        className='w-full p-2  text-[#003f97] bg-transparent  rounded'
      >
        Effacer les filtres
      </button>
    </div>
  );
};

export default SearchFilters;
