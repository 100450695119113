import medicale from '../../public/images/medical-outline.svg';
import globale from '../../public/images/global.svg';
import formation from '../../public/images/doctor-standing.svg';

const Avantages = () => {
  return (
    <div className=' pt-10 pb-10 md:pb-28'>
      <div className=' w-[95%]  lg:w-[90%] 2xl:w-[80%] mx-auto'>
        <ul className=' grid-cols-1 gap-5 grid md:grid-cols-3'>
          <li className='  p-4 border-[1px] border-[#00065e4b] rounded-md flex-col xl:flex-row items-center  flex xl:space-x-4 xl:items-start'>
            <div>
              <img
                src={medicale}
                alt='images'
                className=' h-[60px] w-[60px] object-cover'
              />
            </div>
            <div>
              <h3 className='text-center xl:text-start text-[1.1rem] font-bold text-[#00075e]'>
                Une Plateforme <span className=' text-[#5bc199]'>Médicale</span>{' '}
                Dynamique
              </h3>
              <p className='text-center xl:text-start text-[0.8rem]'>
                Accédez à des ressources et un réseau professionnel mondial.
              </p>
            </div>
          </li>
          <li className=' p-4 border-[1px] border-[#00065e4b] rounded-md flex-col xl:flex-row items-center  flex xl:space-x-4 xl:items-start'>
            <div>
              <img
                src={globale}
                alt='images'
                className='  h-[60px] w-[60px]object-cover'
              />
            </div>
            <div>
              <h3 className='text-center xl:text-start text-[1.1rem] font-bold text-[#00075e]'>
                Une Connexion{' '}
                <span className=' text-[#5bc199]'>Professionnelle</span> Globale
              </h3>
              <p className='text-center xl:text-start text-[0.8rem]'>
                Partagez des connaissances et collaborez avec des experts
                internationaux.
              </p>
            </div>
          </li>
          <li className=' p-4 border-[1px] border-[#00065e4b] rounded-md flex-col xl:flex-row items-center  flex xl:space-x-4 xl:items-start'>
            <div>
              <img
                src={formation}
                alt='images'
                className='  h-[60px] w-[60px] object-cover'
              />
            </div>
            <div>
              <h3 className=' text-center xl:text-start text-[1.1rem] font-bold text-[#00075e]'>
                Une <span className=' text-[#5bc199]'>Opportunité</span> de
                Formation Continue
              </h3>
              <p className='text-center xl:text-start text-[0.8rem]'>
                Bénéficiez de formations spécialisées pour enrichir votre
                pratique médicale.
              </p>
            </div>
          </li>
        </ul>
        <div className='  flex  pt-8'>
          <div className=' w-full lg:w-[55%]  '>
            <h2 className=' text-[1.4rem] font-semibold  text-[#00075e]'>
              <span className=' text-[#5bc199]'>Ceisna</span>, Réseau Mondial de
              Spécialistes
            </h2>
            <p>
              Ceisna vous relie à une communauté mondiale de spécialistes,
              offrant collaboration, ressources spécialisées et formations
              continues pour enrichir votre pratique et développer votre réseau
              professionnel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avantages;
