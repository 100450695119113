import React from 'react';
import facebook from '../../public/images/facebook.svg';
import twitter from '../../public/images/twitter.svg';
import linkedin from '../../public/images/linkedin.svg';
import youtube from '../../public/images/youtube.svg';
import { Link } from 'react-router-dom';

const JoindUs = () => {
  return (
    <div className='joindus bg-[#fcebde] mb-5'>
      <divb className='flex md:flex-row flex-col w-[95%] md:w-[80%] items-center justify-between mx-auto py-16 '>
        <div className=''>
          <p className='text-[#00075e] text-[1.2rem] md:text-[1.6rem] font-bold'>
            <span>Restez en contact avec nous</span>
          </p>
        </div>
        <div>
          <ul className=' flex space-x-3 items-center'>
            <li>
              <Link
                to='https://www.facebook.com/profile.php?id=61555264717310'
                target='_blank'
                rel='noopener noreferrer'
                className='network__facebook'
              >
                <img src={facebook} className=' h-8  md:h-8' alt='Facebook' />
              </Link>
            </li>
            <li>
              <Link
                to='https://www.youtube.com/channel/UCTUutoJE6UTAoHEtEIHxepg'
                target='_blank'
                rel='noopener noreferrer'
                className='network__youtube'
              >
                <img src={youtube} className=' h-5  md:h-6' alt='youtube' />
              </Link>
            </li>
            <li>
              <Link
                to='https://x.com/CEISNAAfrique'
                target='_blank'
                rel='noopener noreferrer'
                className='network__twitter'
              >
                <img src={twitter} className=' h-5  md:h-6' alt='twitter' />
              </Link>
            </li>
            <li>
              <Link
                to='https://www.linkedin.com/company/comit-des-experts-internationaux-en-sant-num-rique-pour-afrique-ceisna/?viewAsMember=true'
                target='_blank'
                rel='noopener noreferrer'
                className='network__linkedin'
              >
                <img src={linkedin} className=' h-4  md:h-5' alt='linkedin' />
              </Link>
            </li>
          </ul>
        </div>
      </divb>
    </div>
  );
};

export default JoindUs;
