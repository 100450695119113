import React from 'react';
import FooterMenu from '../componants/Footer/FooterMenu';
import FooterSubCategories from '../componants/Footer/FooterSubCategories';
import Copyright from '../componants/Footer/Copyright';

const Footer = () => {
  return (
    <footer>
      <FooterMenu />
      <FooterSubCategories />
      <Copyright />
    </footer>
  );
};

export default Footer;
