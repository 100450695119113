import RapportEvenement from '../componants/Events/RapportEvenement';
import PageHeader from '../componants/utilities/PageHeader';
import { useCollection } from '../hooks/useCollection';
import banner from '../public/images/carousel_5.png';

const RapportEvents = () => {
  const pageHeader = {
    title: "Rapport d'évènement.",
    description:
      "Rapport d'évènement : Document détaillant les activités, observations et conclusions d'un événement, incluant les participants et les résultats principaux.",
    banner: banner,
  };

  const { documents, loading, error } = useCollection('events');

  return (
    <main>
      <PageHeader w={'55%'} pageHeader={pageHeader} />
      <RapportEvenement docs={documents} />
    </main>
  );
};

export default RapportEvents;
