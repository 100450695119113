import banner from '../../public/images/carousel_4.png';

const ContactHeader = () => {
  return (
    <div
      style={{
        background: `url(${banner})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
      }}
      className=' overflow-hidden relative h-[25vh] w-full flex items-end'
    >
      <div
        style={{
          clipPath: 'polygon(18% 66%, 76% 68%, 82% 100%, 0 100%, 0 0)',
        }}
        className=' left-0 -bottom-2 absolute w-full bg-white h-[100%] flex items-end mt-4'
      >
        <div className=' w-[80%] mx-auto'>
          <h2 className=' text-[1.3rem] md:text-[1.5rem] lg:text-[2rem] font-semibold capitalize  text-[#00075e]'>
            Contactez nous
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
