import React from 'react';

const AboutContent = () => {
  return (
    <div className=' py-10'>
      <div>
        <div className=' w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
          <div>
            <h2 className='text-2xl font-bold text-gray-800 mb-4'>
              Le Comité d'Experts Internationaux en Santé Numérique pour
              l'Afrique (CEISNA) : Un Pilier Essentiel pour l’Avenir de la Santé
              Continentale
            </h2>
            <p className='text-gray-700 mb-4'>
              En Afrique, la révolution numérique ne touche pas seulement
              l'économie et la communication, mais aussi le secteur de la santé.
              Le Comité d’Experts Internationaux en Santé Numérique pour
              l’Afrique (CEISNA) joue un rôle central dans ce changement
              radical. Créé pour guider et soutenir l’intégration des
              technologies numériques dans les systèmes de santé africains, le
              CEISNA est un acteur clé dans la modernisation des infrastructures
              sanitaires du continent.
            </p>
            <h3 className='text-xl font-semibold text-gray-800 mb-2'>
              Un Catalyseur de Changement
            </h3>
            <p className='text-gray-700 mb-4'>
              Depuis sa création, le CEISNA a été un catalyseur important dans
              l’évolution des systèmes de santé africains. Son objectif est de
              promouvoir l’adoption et l’intégration des solutions numériques
              afin d’améliorer la qualité des soins, l’accès aux services de
              santé et la gestion des données sanitaires.
            </p>
            <blockquote className='border-l-4 border-blue-500 pl-4 text-gray-700 italic mb-4'>
              Mme Laure Beyala, fondatrice du Comité d'Experts Internationaux en
              Santé Numérique pour l'Afrique (CEISNA), explique : « Le CEISNA a
              pour mission de conseiller les gouvernements africains et les
              institutions locales sur la manière dont les technologies
              numériques peuvent transformer la santé publique. Nous nous
              concentrons sur des solutions qui répondent aux défis uniques du
              continent, comme la gestion des épidémies et l'amélioration de
              l’accès aux soins dans les zones reculées. »
            </blockquote>
            <h3 className='text-xl font-semibold text-gray-800 mb-2'>
              Un Défi Permanent
            </h3>
            <p className='text-gray-700 mb-4'>
              Malgré les succès enregistrés, le chemin reste semé d'embûches.
              L’intégration des technologies numériques en santé est confrontée
              à des défis comme l'infrastructure limitée, le manque de formation
              et les disparités économiques. Pour répondre à ces enjeux, le
              CEISNA ne se contente pas de fournir des recommandations ; il
              collabore également avec les partenaires locaux pour développer
              des solutions adaptées aux réalités africaines.
            </p>
            <blockquote className='border-l-4 border-blue-500 pl-4 text-gray-700 italic mb-4'>
              Bamadio Tidiane, Expert en Communication en santé numérique au
              sein du CEISNA, souligne : « Le CEISNA joue un rôle crucial non
              seulement en apportant des expertises internationales mais aussi
              en facilitant l’échange de bonnes pratiques et en soutenant les
              initiatives locales. Leur approche collaborative est essentielle
              pour surmonter les défis et garantir que les solutions numériques
              apportent des bénéfices tangibles pour les communautés locales. »
            </blockquote>
            <p className='text-gray-700'>
              Le CEISNA se positionne comme un acteur indispensable dans la
              modernisation des systèmes de santé africains. En apportant son
              expertise et en facilitant des solutions adaptées, il joue un rôle
              crucial dans l'amélioration de la santé publique sur le continent,
              offrant ainsi une lueur d’espoir pour un avenir où la santé
              numérique pourra bénéficier à tous.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
