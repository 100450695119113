const AboutHero = ({ heroText }) => {
  return (
    <div className=' bg-[#f3f6f8] py-8 md:py-16'>
      <div className=' w-[95%]  text-[0.9rem] md:text-[1rem] text-[#474747] md:w-[70%] xl:w-[60%] 2xl:w-[50%] mx-auto'>
        <p>{heroText}</p>
      </div>
    </div>
  );
};

export default AboutHero;
