import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import 'leaflet.markercluster';
import { mockUsers } from '../../data/experts';

const ClusteringMarkers = ({ filteredUsers }) => {
  const map = useMap();

  useEffect(() => {
    // Crée un groupe de clustering
    const markers = L.featureGroup();
    const markerCluster = L.markerClusterGroup();

    filteredUsers.forEach((user) => {
      const marker = L.marker([user.latitude, user.longitude]);
      marker.bindPopup(user.name);
      markers.addLayer(marker);
    });

    markerCluster.addLayers(markers.getLayers());
    map.addLayer(markerCluster);

    // Nettoyage lors du démontage du composant
    return () => {
      map.removeLayer(markerCluster);
    };
  }, [map, filteredUsers]);

  return null;
};

const MapComponent = ({ filteredUsers }) => {
  return (
    <div className=' h-[50vh] lg:h-[80vh] pt-6 -z-50'>
      <MapContainer
        center={[7.369722, 12.354722]}
        zoom={4.5}
        style={{ height: '100%', width: '100%', zIndex: 0 }}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        <ClusteringMarkers filteredUsers={filteredUsers} />
      </MapContainer>
    </div>
  );
};

export default MapComponent;
