import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/commission-scientifique.png';

const CommissionsScientifiques = () => {
  const pageHeader = {
    title: 'Commission Coopération Internationale',
    description:
      'La Commission Coopération Internationale du CEISNA promeut la collaboration internationale pour développer la santé numérique en Afrique via des partenariats stratégiques.',
    banner: banner,
  };

  const heroText = `La Commission Coopération Internationale du CEISNA vise à promouvoir la collaboration internationale pour soutenir le développement de la santé numérique en Afrique, en établissant des partenariats avec des organisations et des agences internationales.`;

  const listElements = {
    title: 'Missions:',
    enum: [
      'Établir des partenariats avec des organisations internationales et des agences de santé.',
      'Participer à des projets et initiatives internationaux.',
      "Organiser des missions d'étude et d'échange avec des partenaires étrangers.",
      "Promouvoir l'intégration des normes internationales en santé numérique en Afrique.",
    ],
  };
  const listElements2 = {
    title: 'Propositions de Valeur:',
    enum: [
      "Accès à un réseau mondial d'experts et de partenaires.",
      'Opportunités de participation à des projets internationaux.',
      "Soutien pour l'adoption des meilleures pratiques et des normes internationales.",
      'Visibilité et reconnaissance sur la scène internationale.',
    ],
  };

  const accordions = [
    {
      title:
        'Comment puis-je participer à des projets internationaux via le CEISNA? ',
      content:
        'Vous pouvez exprimer votre intérêt en remplissant le formulaire de participation sur notre site web ou en nous contactant directement.',
    },
    {
      title:
        'Quels sont les avantages de collaborer avec des partenaires internationaux ? ',
      content:
        "collaborations internationales offrent des opportunités de financement, de partage de connaissances et d'adoption des meilleures pratiques.",
    },
    {
      title: " Le CEISNA facilite-t-il les missions d'étude à l'étranger ? ",
      content:
        "Oui, nous organisons des missions d'étude et d'échange avec des partenaires étrangers pour promouvoir l'apprentissage et la collaboration.",
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default CommissionsScientifiques;
