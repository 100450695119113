export const dropdown = [
  {
    banner: {
      images:
        'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imagedescrition:
        "Structure d'impact social qui soutient les autorités africaines dans le développement des lignes directrices et des recommandations réglementaires dans le domaine du numérique en santé.",
      url: '/',
    },
    pageTitle: 'Accueil',

    links: [
      {
        title: 'Mission',
        url: '/notre-mission',
      },
      {
        title: 'Valeurs',
        url: '/nos-valeurs',
      },
      {
        title: 'Équipe',
        url: '/notre-equipe',
      },
      {
        title: 'Projets',
        url: '/nos-projets',
      },
      {
        title: 'Impact',
        url: '/notre-impact',
      },
    ],

    subject: 'Explorez Davantage Les Sujets Actuels.',
    subjetLinks: [
      {
        title: 'Tribune',
        url: '/actualites/?query=Tribune',
      },
      {
        title: "Paroles d'Experts",
        url: '/actualites/?query=Paroles',
      },
      {
        title: 'Partenariats Stratégiques',
        url: '/actualites/?query=Partenariats',
      },
    ],
  },
  {
    banner: {
      images:
        'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imagedescrition:
        "Structure d'impact social qui soutient les autorités africaines dans le développement des lignes directrices et des recommandations réglementaires dans le domaine du numérique en santé.",
      url: '/',
    },
    pageTitle: 'Commissions',

    links: [
      {
        title: 'Commission Recherche',
        url: '/commissions-recherche',
      },
      {
        title: 'Commission Fomation',
        url: '/commissions-fomation',
      },
      {
        title: 'Commission Entrerprise',
        url: '/cooperation-entreprise',
      },
      {
        title: 'Groupe Consultatif',
        url: '/groupe-consultatif',
      },
    ],

    subject: 'Explorez Davantage Les Sujets Actuels.',
    subjetLinks: [
      {
        title: 'Tribune',
        url: '/actualites/?query=Tribune',
      },
      {
        title: "Paroles d'Experts",
        url: '/actualites/?query=Paroles',
      },
      {
        title: 'Partenariats Stratégiques',
        url: '/actualites/?query=Partenariats',
      },
    ],
  },
  {
    banner: {
      images:
        'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imagedescrition:
        "Structure d'impact social qui soutient les autorités africaines dans le développement des lignes directrices et des recommandations réglementaires dans le domaine du numérique en santé.",
      url: '/',
    },
    pageTitle: 'Séances',

    links: [
      {
        title: "Groupes d'Experts",
        url: '/ressources/groupe-expert',
      },
      {
        title: 'Réunions',
        url: '/ressources/renuon',
      },
      {
        title: 'Publications',
        url: '/ressources/publications-et-rapports',
      },
      {
        title: 'Bibliothèque',
        url: '/ressources/bibliotheque',
      },
    ],

    subject: 'Explorez Davantage Les Sujets Actuels.',
    subjetLinks: [
      {
        title: 'Tribune',
        url: '/actualites/?query=Tribune',
      },
      {
        title: "Paroles d'Experts",
        url: '/actualites/?query=Paroles',
      },
      {
        title: 'Partenariats Stratégiques',
        url: '/actualites/?query=Partenariats',
      },
    ],
  },
  {
    banner: {
      images:
        'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imagedescrition:
        "Structure d'impact social qui soutient les autorités africaines dans le développement des lignes directrices et des recommandations réglementaires dans le domaine du numérique en santé.",
      url: '/',
    },
    pageTitle: 'Membres',

    links: [
      {
        title: 'Commission Recherche',
        url: '/commissions-recherche',
      },
      {
        title: 'Commission Fomation',
        url: '/commissions-fomation',
      },
      {
        title: 'Coopération Avec Les Entrerprises',
        url: '/cooperation-entreprise',
      },
      {
        title: 'Groupe Consultatif',
        url: '/groupe-consultatif',
      },
    ],

    subject: 'Explorez Davantage Les Sujets Actuels.',
    subjetLinks: [
      {
        title: 'Tribune',
        url: '/actualites/?query=Tribune',
      },
      {
        title: "Paroles d'Experts",
        url: '/actualites/?query=Paroles',
      },
      {
        title: 'Partenariats Stratégiques',
        url: '/actualites/?query=Partenariats',
      },
    ],
  },
  {
    banner: {
      images:
        'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imagedescrition:
        "Structure d'impact social qui soutient les autorités africaines dans le développement des lignes directrices et des recommandations réglementaires dans le domaine du numérique en santé.",
      url: '/',
    },
    pageTitle: 'Actualités',

    links: [
      {
        title: 'Commissions Recherche',
        url: '/commissions-recherche',
      },
      {
        title: 'Commissions Fomation',
        url: '/commissions-fomation',
      },
      {
        title: 'Coopération Avec Les Entrerprises',
        url: '/cooperation-entreprise',
      },
      {
        title: 'Groupe Consultatif',
        url: '/groupe-consultatif',
      },
    ],

    subject: 'Explorez Davantage Les Sujets Actuels.',
    subjetLinks: [
      {
        title: 'Tribune',
        url: '/actualites/?query=Tribune',
      },
      {
        title: "Paroles d'Experts",
        url: '/actualites/?query=Paroles',
      },
      {
        title: 'Articles',
        url: '/actualites/?query=Articles',
      },
    ],
  },
];
