import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MobileMenu = ({ openModal, setOpenModal }) => {
  return (
    <div
      onClick={() => setOpenModal(false)}
      className={`fixed z-[999] transition-all duration-500 bg-[#00000068] w-full -top-0 ${
        openModal ? 'translate-x-0' : 'translate-x-[50rem]'
      }   h-[100vh] lg:hidden`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=' absolute top-0 bottom-0 right-0 pt-2 z-[99999] bg-[#00075e]  w-[70%] h-full '
      >
        <ul className=' space-y-2 text-[#fff] w-full flex flex-col  px-2 items-start  lg:w-[80%] capitalize mx-auto  justify-between text-[1.2rem]'>
          <li
            onClick={() => setOpenModal(false)}
            className={`py-[0.5rem] px-2 text-[0.9rem]  w-full   transition-all border-b-[0.1px] border-b-[#ffffff6d]`}
          >
            <Link to='/ressources/groupe-expert'>Groupe d'expert</Link>
          </li>
          <li
            onClick={() => setOpenModal(false)}
            className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'
          >
            <Link to='/ressources/renuon'>Rénuon</Link>
          </li>
          <li
            onClick={() => setOpenModal(false)}
            className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all  border-b-[0.1px] border-b-[#ffffff6d]'
          >
            <Link to='/ressources/publications-et-rapports'>
              Publications et rapports
            </Link>
          </li>
          <li
            onClick={() => setOpenModal(false)}
            className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'
          >
            <Link to='/ressources/bibliotheque'>
              Bibliothèque de ressources
            </Link>
          </li>
          <li
            onClick={() => setOpenModal(false)}
            className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'
          >
            <Link to='/ressources/organisations'>organisations partenaire</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const HeaderRessources = () => {
  const location = useLocation();
  console.log(location);
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className=' relative text-white w-full justify-end flex  items-end bg-[#00075e] md:py-5 2xl:py-1'>
      <MobileMenu openModal={openModal} setOpenModal={setOpenModal} />
      <ul className=' hidden lg:flex w-[95%] md:w-[80%] mx-auto pt-6 mt-5  items-center pb-[1px] space-x-6'>
        <li
          className={`${
            location.pathname === '/ressources/groupe-expert' ||
            location.pathname === '/ressources'
              ? 'bg-white text-[#00075e]'
              : ''
          } duration-500 transition-all  p-2 hover:bg-white hover:text-[#00075e]`}
        >
          <Link to={'/ressources'}>Groupe d'expert</Link>
        </li>
        <li
          className={`${
            location.pathname === '/ressources/renuon'
              ? 'bg-white text-[#00075e]'
              : ''
          } duration-500 transition-all  p-2 hover:bg-white hover:text-[#00075e]`}
        >
          <Link to={'/ressources/renuon'}>Réunions</Link>
        </li>
        <li
          className={`${
            location.pathname === '/ressources/publications-et-rapports'
              ? 'bg-white text-[#00075e]'
              : ''
          } duration-500 transition-all  p-2 hover:bg-white hover:text-[#00075e]`}
        >
          <Link to={'/ressources/publications-et-rapports'}>Publications</Link>{' '}
        </li>
        <li
          className={`${
            location.pathname === '/ressources/bibliotheque'
              ? 'bg-white text-[#00075e]'
              : ''
          } duration-500 transition-all  p-2 hover:bg-white hover:text-[#00075e]`}
        >
          <Link to={'/ressources/bibliotheque'}>Bibliothèque</Link>{' '}
        </li>
        <li
          className={`${
            location.pathname === '/ressources/organisations'
              ? 'bg-white text-[#00075e]'
              : ''
          } duration-500 transition-all  p-2 hover:bg-white hover:text-[#00075e]`}
        >
          <Link to={'/ressources/organisations'}>Partenaires</Link>{' '}
        </li>
      </ul>
      <button
        onClick={() => setOpenModal(true)}
        className='block pb-1 pt-11 lg:hidden'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className=' h-8 w-8'
        >
          <path
            fillRule='evenodd'
            d='M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  );
};

export default HeaderRessources;
