import React, { useState } from 'react';
import SwitchButton from '../../utilities/SwitchButton';
import plan_img from './../../public/images/plan_image_2.png';

const Plan = () => {
  const [isPersonel, setIspersonel] = useState(true);
  return (
    <div className=' py-10 w-[95%]  lg:w-[90%] 2xl:w-[80%] mx-auto'>
      <div className=' flex w-full   items-center justify-between'>
        <div>
          <h4 className='text-[#5bc199] text-lg'>Nos cotisations</h4>
          <h2 className='text-[#00075e] text-[1.5rem] lg:text-[2rem] font-bold'>
            Cotisations & adhésions
          </h2>
        </div>
        <div className=' lg:w-[41%]'>
          {/* <SwitchButton setIspersonel={setIspersonel} isPersonel={isPersonel} /> */}
        </div>
      </div>
      <div className=' flex-col lg:flex-row flex py-2  items-center'>
        <ul className=' w-full lg:w-[65%] py-8  flex flex-col space-y-3'>
          <li className='flex items-center space-x-2 '>
            <div className=' p-[3rem] bg-[#5985ba] rounded-tr-[60px]  rounded-bl-[60px] h-[100px]  w-[100px] flex items-center justify-center'>
              <p className='text-lg block font-semibold  text-[#ffffff]'>
                <span className=' text-[1.6rem]'> 35€</span>/mois
              </p>
            </div>
            <div>
              <h2 className='text-xl font-bold text-[#00075e]'>
                Personne physique en Afrique
              </h2>
              <p className='text-sm text-gray-600'>
                Accès complet aux services pour les professionnels individuels
                en Afrique.
              </p>
            </div>
          </li>
          <li className='flex items-center space-x-2 '>
            <div className=' bg-[#5bc199] rounded-tr-[60px]  rounded-bl-[60px] h-[100px] p-[3rem] w-[100px] flex items-center justify-center'>
              <p className='text-lg block font-semibold  text-[#ffffff]'>
                <span className=' text-[1.6rem]'>95€</span>/mois
              </p>
            </div>
            <div>
              <h2 className='text-xl font-bold text-[#00075e]'>
                Personne physique hors Afrique
              </h2>
              <p className='text-sm text-gray-600'>
                Abonnement mensuel pour les professionnels individuels en dehors
                de l'Afrique, accès complet aux services.
              </p>
            </div>
          </li>
          <li className='flex items-center space-x-2 '>
            <div className=' overflow-hidden relative flex-col  bg-[#213768] rounded-tr-[60px]  rounded-bl-[60px] h-[125px]  w-[125px] pl-2 flex items-start justify-center'>
              <span className='  text-center bg-[#fc59ff] w-[120px] rotate-[-28deg] -left-4 top-3 absolute block text-[0.7rem] text-white'>
                A partir de
              </span>
              <p className='text-lg block font-semibold  text-[#ffffff]'>
                <span className=' text-[1.6rem]'>50€</span>/mois
              </p>
            </div>
            <div className=' flex-1'>
              <h2 className='text-xl font-bold text-[#00075e]'>
                Personnes Morales Résidence en Afrique
              </h2>
              <p className='text-sm text-gray-600'>
                Abonnement mensuel pour les entreprises en Afrique, accès
                complet aux services pour une gestion efficace.
              </p>
            </div>
          </li>
          <li className='flex items-center space-x-2 '>
            <div className=' overflow-hidden relative flex-col  bg-[#a44777] rounded-tr-[60px]  rounded-bl-[60px] h-[125px]  w-[125px] pl-2 flex items-center justify-center'>
              <span className='  text-center bg-[#00075e] w-[135px] px-2 rotate-[-28deg] -left-4 top-4 absolute block text-[0.7rem] text-white'>
                En fonction du chiffre d'Affaires
              </span>
              <p className='text-lg mt-8 flex items-center font-semibold  text-[#ffffff]'>
                <span className=' text-[1.6rem]'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    class='size-6 h-10 w-10'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z'
                    />
                  </svg>
                </span>
              </p>
            </div>
            <div className=' flex-1'>
              <h2 className='text-xl font-bold text-[#00075e]'>
                Personnes Morales Résidence hors Afrique
              </h2>
              <p className='text-sm text-gray-600'>
                Abonnement adapté aux entreprises résidant hors Afrique, tarifs
                variables selon le chiffre d'affaires.
              </p>
            </div>
          </li>
        </ul>
        <div className=' relative w-[100%] lg:w-[35%] h-[230px] sm:h-[500px] '>
          <div className=' z-20 relative'>
            <img
              src={plan_img}
              alt='image'
              className='  h-full  w-full object-cover '
            />
          </div>
          <div className=' -z-1 top-[50%] lg:top-[22%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute h-[300px] w-[300px] md:h-[250px] md:w-[250px] lg:h-[350px] lg:w-[350px] xl:h-[500px] xl:w-[500px]  bg-[#00075e] rounded-full'></div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
