import React from 'react';
import MemberHeader from '../componants/BecomeMember/MemberHeader';
import Avantages from '../componants/BecomeMember/Avantages';
import FormSection from '../componants/BecomeMember/FormSection';
import Plan from '../componants/BecomeMember/Plan';

const BecomeMember = () => {
  return (
    <main>
      <MemberHeader />
      <Avantages />
      <FormSection />
      <Plan />
    </main>
  );
};

export default BecomeMember;
