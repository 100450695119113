import React from 'react';
import { Link } from 'react-router-dom';
import gaol from './../../public/images/gaol.svg';
import value from './../../public/images/values.svg';
import team from './../../public/images/team.svg';
import project from './../../public/images/ambulance.svg';
import impact from './../../public/images/project.svg';
import contact from './../../public/images/contact.svg';
import HeaderTitle from '../../utilities/HeaderTitle';

const NotreMission = () => {
  return (
    <div className=''>
      <div className=' w-[95%] lg:w-[80%] py-10 mx-auto'>
        <HeaderTitle>Que faisons nous?</HeaderTitle>
        <div>
          <div className=' gap-4 md:gap-8 grid notremission__items'>
            <Link
              to={'/notre-mission'}
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] duration-300 transition-all hover:scale-[1.02] hover:bg-[#00c4cc] bg-[#00c5cc43]'
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={gaol} alt='notre mission' className='h-full ' />
              </div>

              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Mission
              </h3>
              <p>
                Améliorer la santé publique en promouvant des initiatives et
                politiques de soins de santé efficaces.
              </p>
            </Link>

            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px]  hover:scale-[1.02] duration-300 transition-all hover:bg-[#38b6ff] bg-[#38b6ff5a]'
              to={'/nos-valeurs'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={value} alt='image' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Nos Valeurs
              </h3>
              <p>
                Engagement envers l'excellence, l'intégrité, la transparence et
                l'innovation dans tous nos projets de santé.
              </p>
            </Link>
            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] hover:scale-[1.02]  duration-300 transition-all hover:bg-[#94b9ff] bg-[#94b9ff94]'
              to={'/notre-equipe'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={team} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Équipe
              </h3>
              <p>
                Une équipe dédiée de professionnels de santé, d'experts et de
                chercheurs passionnés par le bien-être.
              </p>
            </Link>
            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] hover:scale-[1.02]  duration-300 transition-all hover:bg-[#a6cde0] bg-[#cce2ed]'
              to={'/nos-projets'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={project} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Nos Projets
              </h3>
              <p>
                Développement et mise en œuvre de programmes de santé
                communautaires pour améliorer la qualité de vie.
              </p>
            </Link>
            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px] hover:scale-[1.02] duration-300 transition-all hover:bg-[#c0c7df] bg-[#e2e5f0]'
              to={'/notre-impact'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={impact} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Notre Impact
              </h3>
              <p>
                Transformations significatives dans les communautés grâce à nos
                initiatives de santé et de prévention.
              </p>
            </Link>
            <Link
              className=' overflow-hidden relative p-8 rounded-tr-[100px]  rounded-bl-[100px]  hover:scale-[1.02] duration-300 transition-all hover:bg-[#c3e1b7] bg-[#e1f0db]'
              to={'/contactez-nous'}
            >
              {/* <div className=' rotate-45 -right-[8rem] absolute top-0  bg-white w-[300px]  h-[70px]  '></div> */}
              <div className=' h-[3.5rem] mb-2 border-[#00075e] border-[1px] inline-block p-3'>
                <img src={contact} alt='images' className='h-full ' />
              </div>
              <h3 className=' text-[1.5rem] mb-2 text-[#00075e] font-semibold'>
                Contactez-Nous
              </h3>
              <p>
                Ouverts à vos questions, collaborations et suggestions pour
                construire un avenir plus sain ensemble.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotreMission;
