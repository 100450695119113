import React from 'react';
import { useNavigate } from 'react-router-dom';

const OurGroup = () => {
  const navigate = useNavigate();
  return (
    <div className='group '>
      <div className=' h-full py-12  grid grid-cols-1 md:grid-cols-2 w-[95%]  md:w-[80%] mx-auto'>
        <div className='bg-[#003f97] text-[#ffff] flex items-center p-6'>
          <div>
            <h1 className=' text-[1.2rem] md:text-[1.3rem] lg:text-[1.8rem] text-[#ffff] font-bold mb-3'>
              70+ spécialistes mondiaux.
            </h1>

            <p>
              Nous réunissons plus de 70 spécialistes en santé numérique,
              offrant des services médicaux de pointe et des conseils de santé
              de haute qualité à l'échelle mondiale, intégrant les dernières
              technologies numériques pour améliorer les soins.
            </p>
            <button
              onClick={() => navigate('/experts')}
              className=' border-[1px] border-white px-6 py-2 mt-4 rounded-full  '
            >
              Savoir plus
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default OurGroup;
