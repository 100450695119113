import { useEffect, useState } from 'react';
import img from '../../public/images/b_6.png';
import SwitchButton from '../../utilities/SwitchButton';
import MemberForm from './MemberForm';

const FormSection = () => {
  const [isPersonel, setIspersonel] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [dataNaissance, setDataNaissance] = useState('');

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    // Initial check to handle pre-filled input
    setIsFocused(dataNaissance.length > 0);
  }, [dataNaissance]);

  return (
    <div style={{ background: `url(${img})` }} className='   xl:h-[75vh] '>
      <div className=' w-[95%]  lg:w-[90%] 2xl:w-[80%] relative mx-auto  h-full flex flex-col xl:flex-row items-center'>
        <div className=' w-full xl:w-[55%] mb-8 md:mb-0'>
          <h2 className='text-3xl font-bold mb-4 text-[#00075e]'>
            Rejoindre Ceisna
          </h2>
          <p className='mb-4'>
            Connectez-vous à une communauté mondiale de spécialistes, offrant
            collaboration, ressources spécialisées et formations continues pour
            enrichir votre pratique et développer votre réseau professionnel.
          </p>
          <ul className=' pl-5'>
            <li className='mb-2 flex items-center'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='size-6 text-[#5bc199]'
                >
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span> Opportunités de collaboration interdisciplinaire</span>
            </li>
            <li className='mb-2 flex items-center'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='size-6 text-[#5bc199]'
                >
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span>Accès à des ressources spécialisées</span>
            </li>
            <li className='mb-2 flex items-center'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='size-6 text-[#5bc199]'
                >
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span>Formations continues avancées</span>
            </li>
            <li className='mb-2 flex items-center'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='size-6 text-[#5bc199]'
                >
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span> Développement professionnel et personnel</span>
            </li>
          </ul>
        </div>

        <div className=' w-full xl:w-[45%] xl:absolute -top-[10rem] right-0 bg-[#003f97] p-2 sm:p-6 rounded-lg shadow-lg'>
          <div className=''>
            <div className=' flex items-center justify-center'>
              <h3 className='text-xl font-bold text-white mb-4'>
                Inscrivez-vous
              </h3>
            </div>

            <SwitchButton
              setIspersonel={setIspersonel}
              isPersonel={isPersonel}
            />

            <MemberForm
              dataNaissance={dataNaissance}
              isFocused={isFocused}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              setDataNaissance={setDataNaissance}
              isPersonel={isPersonel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
