import { useMemo, useState } from 'react';
import HeaderRessources from '../componants/Ressources/HeaderRessources';
import RessourcesContent from '../componants/Ressources/RessourcesContent';
import { useCollection } from '../hooks/useCollection';

const Renuon = () => {
  const { documents, loading, error } = useCollection('seances');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState('');

  let docs = documents;

  const filteredDocs = useMemo(() => {
    let filtered = documents;

    if (startDate && endDate) {
      filtered = filtered.filter((doc) => {
        const docDate = new Date(doc.startDate.toDate());
        return (
          Date.parse(docDate) >= Date.parse(startDate) &&
          Date.parse(docDate) <= Date.parse(endDate)
        );
      });
    }

    if (search) {
      filtered = filtered.filter((doc) =>
        doc.group.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (group) {
      filtered = filtered.filter((doc) =>
        doc.type.toLowerCase().includes(group.toLowerCase())
      );
    }

    // Filtrer par type si applicable
    filtered = filtered.filter((doc) => doc.type === 'reunions');

    return filtered;
  }, [documents, startDate, endDate, search, group]);

  return (
    <main>
      <HeaderRessources />
      <RessourcesContent
        title='Réunions'
        startDate={startDate}
        endDate={endDate}
        search={search}
        group={group}
        setStartDate={setStartDate}
        setGroup={setGroup}
        setSearch={setSearch}
        setEndDate={setEndDate}
        loading={loading}
        docs={filteredDocs}
      />
    </main>
  );
};

export default Renuon;
