import React, { useState } from 'react';
import EventsCard from './EventsCard';

const Evenements = ({ docs }) => {
  const detailsUrl = '/evenements/';
  const [newsLength, setNewsLength] = useState(6);
  const [filter, setFilter] = useState('');

  let events = docs.slice(0, newsLength);
  const isAllLoaded = newsLength >= docs.length;

  if (filter !== '') {
    events = events.filter((event) => event.type === filter);
  }

  return (
    <main className=' py-10 bg-[#ece9e5]'>
      <div className='w-[95%] md:w-[80%] lg:w-[70%] xl:w-[55%] mx-auto'>
        <ul>
          <li className=' border-[1px] border-b-white mb-4 flex space-x-6 pb-2'>
            <button
              onClick={() => setFilter('')}
              className={` text-[1.2rem]  font-medium  ${
                filter === '' ? 'text-[#ed4f91]' : ''
              } `}
            >
              Tous
            </button>
            <button
              onClick={() => setFilter('Avenir')}
              className={` text-[1.2rem]  font-medium  ${
                filter === 'Avenir' ? 'text-[#ed4f91]' : ''
              } `}
            >
              A vénir
            </button>
            <button
              onClick={() => setFilter('Passe')}
              className={` text-[1.2rem]  font-medium  ${
                filter === 'Passe' ? 'text-[#ed4f91]' : ''
              } `}
            >
              Passé
            </button>
          </li>
        </ul>
        {events.length === 0 && (
          <div>
            <h4>Aucun evenements disponible</h4>
          </div>
        )}

        <ul className=' flex flex-col space-y-6 '>
          {events.map((event, index) => (
            <EventsCard event={event} key={index} detailsUrl={detailsUrl} />
          ))}
        </ul>

        {events.length > 0 && (
          <div className=' py-4 flex justify-center items-center'>
            <button
              onClick={() => setNewsLength(newsLength + 6)}
              disabled={isAllLoaded}
              className={` hover:bg-[#d0407c] text-[#fff] rounded-full py-2 border-[#ed4f91] bg-[#ed4f91] border-[1px] px-5 ${
                isAllLoaded ? 'cursor-not-allowed opacity-50' : ''
              }`}
            >
              Charger plus
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default Evenements;
