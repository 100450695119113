const NewContent = ({ document }) => {
  return (
    <div className=' py-10'>
      <div>
        <div className=' w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
          <div>
            <h2 className=' text-[#00075e] font-semibold text-[1.1rem] md:text-[1.8rem] mb-4'>
              {document?.title}
            </h2>
          </div>
          <div
            className='bogs_details'
            dangerouslySetInnerHTML={{ __html: document?.description }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewContent;
