import { useState } from 'react';

const Accordions = ({ accordions }) => {
  const [AccordionIndex, setAccordionIndex] = useState(0);

  return (
    <div className='w-[95%]   lg:w-[60%] mx-auto mt-10'>
      <div>
        <ul className=' flex flex-col  space-y-3'>
          {accordions.map((accordion, i) => (
            <li
              className={` accordions ${
                AccordionIndex === i && 'active'
              } hover:skew-x-0 transition-all  duration-500 rounded-lg py-10 bg-[#f3f6f8]`}
            >
              <div
                onClick={() => setAccordionIndex(AccordionIndex === i ? '' : i)}
                className='px-2 md:px-10 cursor-pointer items-start flex justify-between space-x-4'
              >
                <h3 className='text-[#00075e] flex-1 text-[0.9rem] md:text-[1.2rem] font-semibold'>
                  {accordion.title}
                </h3>
                <div className=' mt-1 w-[10%] j flex'>
                  <span className=' text-[#00075e] font-[500]'> Lire plus</span>
                  <span>
                    {AccordionIndex === i ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='size-6 text-[#00075e]'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M5 12h14'
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#00075e'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        class='size-6 h-5 w-5 text-[#00075e] font-semibold'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M12 4.5v15m7.5-7.5h-15'
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>
              <div
                className={`${
                  AccordionIndex === i ? 'block' : ' hidden'
                } px-2 md:px-10 py-3`}
              >
                <p>{accordion.content}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Accordions;
