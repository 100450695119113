import { useNavigate } from 'react-router-dom';
import img from '../../public/images/b_1.png';

const EventsCard = ({ detailsUrl, event, index }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);

    const dayOfMonth = dateObject.getDate();
    return dayOfMonth;
  };

  const getMonth = (date) => {
    const dateObject = new Date(date);
    const months = [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ];
    const monthName = months[dateObject.getMonth()];
    return monthName;
  };
  return (
    <li key={index} className=' flex-col flex md:flex-row md:space-x-6'>
      <div className=' mb-6 md:mb-0 w-full md:w-[16%]'>
        <div className=' rounded-lg shadow-lg py-7 flex flex-col items-center bg-white'>
          <h4 className=' text-[2rem] font-bold text-[#00075e]'>
            {' '}
            {formatDate(event.startDate.toDate())}{' '}
          </h4>
          <h4 className=' capitalize font-medium text-[#ed4f91]'>
            {getMonth(event.startDate.toDate())}{' '}
          </h4>
        </div>
      </div>
      <div className=' h-[300px] w-full md:w-[25%]'>
        <img
          src={event.image}
          alt='image'
          className=' rounded-md object-cover h-full'
        />
      </div>
      <div className=' flex flex-col space-y-2 flex-1'>
        <h2 className=' text-[1.3rem] font-semibold '>{event.title}</h2>
        <div className='text-[#ed4f91] space-x-2 flex items-center'>
          <span className='  '>{event.location} </span>{' '}
          <span>{event.time} </span>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html:
              event.description.length > 300
                ? event.description.substring(0, 300) + '...'
                : event.description,
          }}
        />
        <div>
          <button
            onClick={() => navigate(detailsUrl + event.id)}
            className=' rounded-full py-2 border-[#ed4f91] border-[1px] px-5'
          >
            Savoir plus
          </button>
        </div>
      </div>
    </li>
  );
};

export default EventsCard;
