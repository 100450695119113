import CommissionsSection from '../componants/Activities/CommissionsSection';
import JoindUs from '../componants/Home/JoindUs';
import MissionHero from '../componants/OurMission/MissionHero';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/Commission.png';

const Activities = () => {
  const pageHeader = {
    title: 'Nos activités',
    description:
      'Consultation médicale, télémédecine, formations professionnelles, services de santé spécialisés, gestion de cas.',
    banner: banner,
  };
  return (
    <div>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero />
      <CommissionsSection />
      <JoindUs />
    </div>
  );
};

export default Activities;
