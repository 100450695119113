import React from 'react';
import banner from './../../public/images/event_2.png';

const EventHeaderDetails = ({ document }) => {
  return (
    <div
      style={{
        background: `linear-gradient(90deg, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.001) 100%), url(${banner})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
      }}
      className=' h-[60vh]'
    >
      <div className='  h-full py-7 w-[95%] md:w-[80%] mx-auto justify-center items-center '>
        <div className='flex  items-center w-full h-full relative  z-10'>
          <div className=' w-full lg:w-[60%] xl:w-[40%]'>
            <div className='text-[#ed4f91] space-x-2 flex items-center'>
              <span>{document?.location} </span> <span>{document?.time} </span>
            </div>
            <h2 className=' leading-[1.2] text-[2rem]  md:text-[3.5rem] text-white'>
              {document?.title}
            </h2>
            <div
              className=' mt-2 text-white'
              dangerouslySetInnerHTML={{
                __html: document?.description,
              }}
            />
          </div>
          <div className=' flex-1'></div>
        </div>
      </div>
    </div>
  );
};

export default EventHeaderDetails;
