import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/commission-recherche.png';

const CommissionsRecherche = () => {
  const pageHeader = {
    title: 'Commissions Recherche',
    description:
      'Les commissions de recherche supervisent, évaluent et encouragent des projets innovants pour avancer la connaissance scientifique.',
    banner: banner,
  };

  const heroText = `La Commission Recherche du CEISNA se consacre à encourager la recherche innovante en santé numérique, en favorisant le développement de nouvelles technologies et en soutenant les projets de recherche qui répondent aux besoins spécifiques du continent africain.`;

  const listElements = {
    title: 'Missions:',
    enum: [
      'Identifier les domaines prioritaires de recherche en santé numérique.',
      'Soutenir et financer des projets de recherche innovants.',
      'Organiser des conférences et des séminaires pour promouvoir la recherche.',
      'Collaborer avec des institutions de recherche et des universités.',
    ],
  };
  const listElements2 = {
    title: 'Propositions de Valeur:',
    enum: [
      'Accès à des financements pour la recherche.',
      'Opportunités de collaboration avec des chercheurs de renom.',
      'Participation à des projets de recherche à fort impact.',
      'Visibilité et reconnaissance au sein de la communauté scientifique.',
    ],
  };

  const accordions = [
    {
      title:
        'Quels sont les critères pour obtenir un financement pour un projet de recherche ?',
      content:
        " Les projets doivent démontrer un fort potentiel d'impact en santé numérique et répondre à des besoins spécifiques identifiés en Afrique.",
    },
    {
      title: ' Comment puis-je soumettre une proposition de recherche ?',
      content:
        ' Les propositions peuvent être soumises via notre portail en ligne, où des instructions détaillées sont fournies.',
    },
    {
      title:
        'Y a-t-il des opportunités de collaboration avec des universités internationales?',
      content:
        'Oui, nous encourageons et facilitons les collaborations avec des institutions de recherche internationales.',
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default CommissionsRecherche;
