export const mockUsers = [
  {
    id: 1,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.88,
    longitude: 11.5021,
  },
  {
    id: 2,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.325,
    longitude: 15.3222,
  },
  {
    id: 3,
    name: 'Marseille',
    country: 'France',
    latitude: 43.2965,
    longitude: 5.3698,
  },
  {
    id: 4,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.82,
    longitude: 11.5021,
  },
  {
    id: 5,
    name: 'Ngandajika',
    country: 'République Démocratique du Congo',
    latitude: -6.762,
    longitude: 23.9561,
  },
  {
    id: 6,
    name: 'Bamako',
    country: 'Pays',
    latitude: 12.6492,
    longitude: -8.0129,
  },
  {
    id: 7,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.365,
    longitude: 15.3222,
  },
  {
    id: 8,
    name: 'Strasbourg',
    country: 'France',
    latitude: 48.5734,
    longitude: 7.7521,
  },
  {
    id: 9,
    name: 'Maradi',
    country: 'Niger',
    latitude: 13.5,
    longitude: 7.1036,
  },
  {
    id: 10,
    name: 'Kinshasa',
    country: 'République Démocratique du Congo',
    latitude: -6.762,
    longitude: 23.9561,
  },
  {
    id: 11,
    name: 'Mbandaka',
    country: 'République démocratique du Congo',
    latitude: 0.0471,
    longitude: 18.2605,
  },
  {
    id: 12,
    name: 'Abomey-Calavi',
    country: 'Bénin',
    latitude: 6.4485,
    longitude: 2.3557,
  },
  {
    id: 13,
    name: 'Abidjan',
    country: 'Côte d’Ivoire',
    latitude: 5.347,
    longitude: -4.0305,
  },
  {
    id: 14,
    name: 'ABIDJAN',
    country: "COTE D'IVOIRE",
    latitude: 5.35,
    longitude: -4.0305,
  },
  {
    id: 15,
    name: 'Goma',
    country: 'République Démocratique du Congo',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 16,
    name: 'Cotonou',
    country: 'BENIN',
    latitude: 6.3603,
    longitude: 2.3612,
  },
  {
    id: 17,
    name: 'Tunis',
    country: 'Tunisie',
    latitude: 36.8065,
    longitude: 10.1815,
  },
  {
    id: 18,
    name: 'Kenitra',
    country: 'Maroc',
    latitude: 34.271,
    longitude: -6.5802,
  },
  {
    id: 19,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0511,
    longitude: 9.7679,
  },
  {
    id: 20,
    name: 'Ouagdougou',
    country: 'Burkina Faso',
    latitude: 12.3714,
    longitude: -1.5197,
  },
  {
    id: 21,
    name: 'Conakry',
    country: 'Guinée',
    latitude: 9.6312,
    longitude: -13.5774,
  },
  {
    id: 22,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0581,
    longitude: 9.7679,
  },
  {
    id: 23,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0521,
    longitude: 9.7779,
  },
  {
    id: 24,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3803,
    longitude: 2.3712,
  },
  {
    id: 25,
    name: 'Mwene Ditu',
    country: 'République Démocratique du Congo',
    latitude: -7.011,
    longitude: 23.4539,
  },
  {
    id: 26,
    name: 'COTONOU',
    country: 'BENIN',
    latitude: 6.3703,
    longitude: 2.3412,
  },
  {
    id: 27,
    name: 'Daloa',
    country: "Côte d'ivoire",
    latitude: 6.8774,
    longitude: -6.4503,
  },
  {
    id: 28,
    name: 'Uvira',
    country: 'République démocratique du Congo',
    latitude: -3.3984,
    longitude: 29.1378,
  },
  {
    id: 29,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.325,
    longitude: 15.3222,
  },
  {
    id: 30,
    name: 'CONAKRY',
    country: 'GUINEE',
    latitude: 9.6212,
    longitude: -13.5784,
  },
  {
    id: 31,
    name: 'KIMPESE',
    country: 'République démocratique du Congo',
    latitude: -5.6214,
    longitude: 14.4412,
  },
  {
    id: 32,
    name: 'Kara',
    country: 'Togo',
    latitude: 9.5519,
    longitude: 1.1868,
  },
  {
    id: 33,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1925,
    longitude: 1.2514,
  },
  {
    id: 34,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3603,
    longitude: 2.3412,
  },
  {
    id: 35,
    name: 'KINSHASA',
    country: 'RDC',
    latitude: -4.365,
    longitude: 15.3002,
  },
  {
    id: 36,
    name: 'Kindu',
    country: 'République démocratique du Congo',
    latitude: -2.9437,
    longitude: 25.9226,
  },
  {
    id: 37,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.7167,
    longitude: -17.4677,
  },
  {
    id: 38,
    name: 'Niamey',
    country: 'Niger',
    latitude: 13.5425,
    longitude: 2.1127,
  },
  {
    id: 39,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1725,
    longitude: 1.2314,
  },
  {
    id: 40,
    name: 'Dakar',
    country: 'SENEGAL',
    latitude: 14.7167,
    longitude: -17.4677,
  },
  {
    id: 41,
    name: 'YAOUNDE',
    country: 'CAMEROUN',
    latitude: 3.81,
    longitude: 11.5121,
  },
  {
    id: 42,
    name: 'Abidjan',
    country: 'Côte d’Ivoire',
    latitude: 5.36,
    longitude: -4.0305,
  },
  {
    id: 43,
    name: 'Rabat',
    country: 'Maroc',
    latitude: 34.0309,
    longitude: -6.8416,
  },
  {
    id: 44,
    name: 'GOMA CITY',
    country: 'REPUBLIQUE DEMOCRATIQUE DU CONGO',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 45,
    name: 'Bamako',
    country: 'MALI',
    latitude: 12.6792,
    longitude: -8.0029,
  },
  {
    id: 46,
    name: 'kinshasa',
    country: 'RDC',
    latitude: -4.375,
    longitude: 15.3022,
  },
  {
    id: 47,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6692,
    longitude: -8.0029,
  },
  {
    id: 48,
    name: 'Marrakech',
    country: 'Béninoise vivant au Maroc',
    latitude: 31.6295,
    longitude: -7.9811,
  },
  {
    id: 49,
    name: 'Agadir',
    country: 'Maroc',
    latitude: 30.4278,
    longitude: -9.5981,
  },
  {
    id: 50,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.385,
    longitude: 15.3222,
  },
  {
    id: 51,
    name: 'Ngandajika',
    country: 'République Démocratique du Congo',
    latitude: -6.742,
    longitude: 23.9561,
  },
  {
    id: 52,
    name: 'Kinshasa',
    country: 'République Démocratique du Congo',
    latitude: -4.305,
    longitude: 15.3222,
  },
  {
    id: 53,
    name: 'Cotonou',
    country: 'Benin',
    latitude: 6.3603,
    longitude: 2.3812,
  },
  {
    id: 54,
    name: 'Matadi',
    country: 'RDC',
    latitude: -5.816,
    longitude: 13.4592,
  },
  {
    id: 55,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.38,
    longitude: -4.0305,
  },
  {
    id: 56,
    name: 'Ndjamena',
    country: 'Tchad',
    latitude: 12.1648,
    longitude: 15.0557,
  },
  {
    id: 57,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.365,
    longitude: 15.3322,
  },
  {
    id: 58,
    name: 'Kinshasa',
    country: 'République démocratique du Congo',
    latitude: -4.355,
    longitude: 15.3422,
  },
  {
    id: 59,
    name: 'Kinshasa',
    country: 'République Démocratique du Congo',
    latitude: -4.365,
    longitude: 15.3622,
  },
  {
    id: 60,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3514,
    longitude: -1.5197,
  },
  {
    id: 61,
    name: 'Kindu',
    country: 'République démocratique du Congo',
    latitude: -2.9437,
    longitude: 25.9226,
  },
  {
    id: 62,
    name: 'KINSHASA',
    country: 'RÉPUBLIQUE DÉMOCRATIQUE DU CONGO',
    latitude: -4.385,
    longitude: 15.3222,
  },
  {
    id: 63,
    name: 'Kinshasa',
    country: 'République démocratique du congo',
    latitude: -4.395,
    longitude: 15.3222,
  },
  {
    id: 64,
    name: 'Kinshasa',
    country: 'RDC',
    latitude: -4.375,
    longitude: 15.3422,
  },
  {
    id: 65,
    name: 'Lubumbashi',
    country: 'République démocratique du Congo',
    latitude: -11.6898,
    longitude: 27.4862,
  },
  {
    id: 66,
    name: 'Walikale',
    country: 'République Démocratique du Congo',
    latitude: -0.1696,
    longitude: 28.0046,
  },
  {
    id: 67,
    name: 'Goma',
    country: 'RDC',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 68,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3603,
    longitude: 2.3012,
  },
  {
    id: 69,
    name: 'Abidjan',
    country: 'Côte-d’Ivoire',
    latitude: 5.315,
    longitude: -4.0505,
  },
  {
    id: 70,
    name: 'Bukavu',
    country: 'RDC',
    latitude: -2.5085,
    longitude: 28.8458,
  },
  {
    id: 71,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1925,
    longitude: 1.2424,
  },
  {
    id: 72,
    name: 'Burkina Faso',
    country: 'Burkina Faso',
    latitude: 12.2383,
    longitude: -1.5616,
  },
  {
    id: 73,
    name: 'Bukavu',
    country: 'République démocratique du Congo',
    latitude: -2.5085,
    longitude: 28.8458,
  },
  {
    id: 74,
    name: 'GOMA',
    country: 'République démocratique du congo',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 75,
    name: 'Goma',
    country: 'RDC',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 76,
    name: 'Nouakchott',
    country: 'Mauritanie',
    latitude: 18.0735,
    longitude: -15.9582,
  },
  {
    id: 77,
    name: 'Goma/Nord - Kivu',
    country: 'République Démocratique du Congo',
    latitude: -1.6585,
    longitude: 29.2203,
  },
  {
    id: 78,
    name: 'Bukavu',
    country: 'République démocratique du Congo',
    latitude: -2.5085,
    longitude: 28.8458,
  },
  {
    id: 79,
    name: 'Lubumbashi',
    country: 'République Démocratique du Congo',
    latitude: -11.6898,
    longitude: 27.4862,
  },
  {
    id: 80,
    name: 'Abomey-Calavi',
    country: 'Bénin',
    latitude: 6.4485,
    longitude: 2.3557,
  },
  {
    id: 81,
    name: 'Kinshasa',
    country: 'Congo Drc',
    latitude: -4.385,
    longitude: 15.3622,
  },
  {
    id: 82,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3803,
    longitude: 2.3512,
  },
  {
    id: 83,
    name: 'Thiès',
    country: 'Sénégal',
    latitude: 14.7903,
    longitude: -16.925,
  },
  {
    id: 84,
    name: 'Moanda',
    country: 'GABON',
    latitude: -1.5657,
    longitude: 13.2083,
  },
  {
    id: 85,
    name: 'Diffa',
    country: 'Niger',
    latitude: 13.3155,
    longitude: 12.6088,
  },
  {
    id: 86,
    name: 'Kinshasa',
    country: 'RDCongo',
    latitude: -4.365,
    longitude: 15.3722,
  },
  {
    id: 87,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.7167,
    longitude: -17.4677,
  },
  {
    id: 88,
    name: 'Pointe-Noire',
    country: 'Congo/Brazza',
    latitude: -4.7692,
    longitude: 11.8635,
  },
  {
    id: 89,
    name: 'Maradi',
    country: 'Niger',
    latitude: 13.5,
    longitude: 7.1036,
  },
  {
    id: 90,
    name: 'Paris',
    country: 'France',
    latitude: 48.8766,
    longitude: 2.3522,
  },
  {
    id: 91,
    name: 'Brazzaville',
    country: 'Congo',
    latitude: -4.2634,
    longitude: 15.2429,
  },
  {
    id: 92,
    name: "N'DJAMENA",
    country: 'Tchad',
    latitude: 12.1648,
    longitude: 15.0657,
  },
  {
    id: 93,
    name: "N'Djaména",
    country: 'Tchad',
    latitude: 12.1248,
    longitude: 15.0547,
  },
  {
    id: 94,
    name: 'Conakry',
    country: 'Guinée',
    latitude: 9.6512,
    longitude: -13.5784,
  },
  {
    id: 95,
    name: 'Mbujimayi',
    country: 'République démocratique du Congo',
    latitude: -6.136,
    longitude: 23.5897,
  },
  {
    id: 96,
    name: 'Kinshasa',
    country: 'République Démocratique du Congo (RDC)',
    latitude: -4.375,
    longitude: 15.3835,
  },
  {
    id: 97,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6792,
    longitude: -8.0029,
  },
  {
    id: 98,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 99,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.33,
    longitude: -4.0805,
  },
  {
    id: 100,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5321,
  },
  {
    id: 101,
    name: 'Niamey Niger',
    country: 'NIGER',
    latitude: 13.5325,
    longitude: 2.1227,
  },
  {
    id: 102,
    name: 'KINSHASA',
    country: 'RD CONGO',
    latitude: -4.35,
    longitude: 15.3022,
  },
  {
    id: 103,
    name: 'Nouakchott',
    country: 'Mauritanie',
    latitude: 18.0735,
    longitude: -15.9582,
  },
  {
    id: 104,
    name: "N'Djamena",
    country: 'Tchad',
    latitude: 12.1648,
    longitude: 15.0507,
  },
  {
    id: 105,
    name: 'Bukavu',
    country: 'République Démocratique du Congo',
    latitude: -2.5085,
    longitude: 28.8458,
  },
  {
    id: 106,
    name: 'Niamey',
    country: 'Niger',
    latitude: 13.5225,
    longitude: 2.1327,
  },
  {
    id: 107,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.347,
    longitude: -4.0525,
  },
  {
    id: 108,
    name: 'Parakou',
    country: 'BÉNIN',
    latitude: 9.336,
    longitude: 2.631,
  },
  {
    id: 109,
    name: 'France',
    country: 'France',
    latitude: 46.6034,
    longitude: 1.8883,
  },
  {
    id: 110,
    name: 'Cotonou',
    country: 'BENIN',
    latitude: 6.3603,
    longitude: 2.3412,
  },
  {
    id: 111,
    name: 'Brazzaville',
    country: 'Congo',
    latitude: -4.2634,
    longitude: 15.2429,
  },
  {
    id: 112,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1425,
    longitude: 1.2314,
  },
  {
    id: 113,
    name: 'MONTREAL',
    country: 'CANADA',
    latitude: 45.5317,
    longitude: -73.5673,
  },
  {
    id: 114,
    name: 'Niamey',
    country: 'Niger',
    latitude: 13.5125,
    longitude: 2.1227,
  },
  {
    id: 115,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 116,
    name: 'Madrid',
    country: 'Spain',
    latitude: 40.4168,
    longitude: -3.7038,
  },
  {
    id: 117,
    name: 'GRAND-BASSAM',
    country: "Côte d'Ivoire",
    latitude: 5.2024,
    longitude: -3.7388,
  },
  {
    id: 118,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.347,
    longitude: -4.0605,
  },
  {
    id: 119,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.347,
    longitude: -4.0005,
  },
  {
    id: 120,
    name: 'ABIDJAN',
    country: "COTE D'IVOIRE",
    latitude: 5.347,
    longitude: -4.0105,
  },
  {
    id: 121,
    name: "L'Aquila",
    country: 'Italie',
    latitude: 42.3499,
    longitude: 13.3995,
  },
  {
    id: 122,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.367,
    longitude: -4.0105,
  },
  {
    id: 123,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.357,
    longitude: -4.0405,
  },
  {
    id: 124,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.307,
    longitude: -4.0405,
  },
  {
    id: 125,
    name: 'Douala',
    country: 'Cameroon',
    latitude: 4.0551,
    longitude: 9.7479,
  },
  {
    id: 126,
    name: 'ABIDJAN',
    country: "CÔTE D'IVOIRE",
    latitude: 5.377,
    longitude: -4.0885,
  },
  {
    id: 127,
    name: 'NATITINGOU',
    country: 'BÉNIN',
    latitude: 10.3047,
    longitude: 1.3786,
  },
  {
    id: 128,
    name: 'Marseille',
    country: 'France',
    latitude: 43.28,
    longitude: 5.3698,
  },
  {
    id: 129,
    name: 'Rennes',
    country: 'France',
    latitude: 48.1173,
    longitude: -1.6778,
  },
  {
    id: 130,
    name: 'Lille',
    country: 'France',
    latitude: 50.6292,
    longitude: 3.0573,
  },
  {
    id: 131,
    name: 'Paris',
    country: 'FRANCE',
    latitude: 48.8666,
    longitude: 2.3522,
  },
  {
    id: 132,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5421,
  },
  {
    id: 133,
    name: 'Ouagadougou',
    country: 'Burkina faso',
    latitude: 12.3314,
    longitude: -1.5697,
  },
  {
    id: 134,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3714,
    longitude: -1.5797,
  },
  {
    id: 135,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6092,
    longitude: -8.0029,
  },
  {
    id: 136,
    name: 'Banfora',
    country: 'Burkina Faso',
    latitude: 10.6343,
    longitude: -4.7669,
  },
  {
    id: 137,
    name: 'Bobo-Dioulasso',
    country: 'Burkina Faso',
    latitude: 11.1884,
    longitude: -4.2777,
  },
  {
    id: 138,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1325,
    longitude: 1.2314,
  },
  {
    id: 139,
    name: 'Bobo-Dioulasso',
    country: 'Burkina Faso',
    latitude: 11.1484,
    longitude: -4.2977,
  },
  {
    id: 140,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 141,
    name: 'KETOU',
    country: 'Bénin',
    latitude: 7.3623,
    longitude: 2.6034,
  },
  {
    id: 142,
    name: 'Yaounde',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5321,
  },
  {
    id: 143,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3814,
    longitude: -1.5797,
  },
  {
    id: 144,
    name: 'Koulikoro',
    country: 'Mali',
    latitude: 12.862,
    longitude: -7.5596,
  },
  {
    id: 145,
    name: 'Dakar',
    country: 'Senegal',
    latitude: 14.7167,
    longitude: -17.4677,
  },
  {
    id: 146,
    name: 'Sénégal',
    country: 'Sénégal',
    latitude: 14.4974,
    longitude: -14.4524,
  },
  {
    id: 147,
    name: 'Tambacounda',
    country: 'Sénégal',
    latitude: 13.7706,
    longitude: -13.6673,
  },
  {
    id: 148,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6192,
    longitude: -8.0029,
  },
  {
    id: 149,
    name: 'Tchamba',
    country: 'Togo',
    latitude: 9.0333,
    longitude: 1.4167,
  },
  {
    id: 150,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 151,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.7267,
    longitude: -17.4677,
  },
  {
    id: 152,
    name: 'Parakou',
    country: 'Bénin',
    latitude: 9.336,
    longitude: 2.631,
  },
  {
    id: 153,
    name: "N'dali",
    country: 'Bénin',
    latitude: 9.7166,
    longitude: 2.7274,
  },
  {
    id: 154,
    name: 'MALI',
    country: 'Mali',
    latitude: 12.6392,
    longitude: -8.0029,
  },
  {
    id: 155,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3714,
    longitude: -1.5697,
  },
  {
    id: 156,
    name: 'Paynesville',
    country: 'Liberia',
    latitude: 6.252,
    longitude: -10.7068,
  },
  {
    id: 157,
    name: 'Kayes',
    country: 'Mali',
    latitude: 14.4419,
    longitude: -11.4409,
  },
  {
    id: 158,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6492,
    longitude: -8.0029,
  },
  {
    id: 159,
    name: 'Akoupe',
    country: "Côté D'ivoire",
    latitude: 6.3831,
    longitude: -3.9012,
  },
  {
    id: 160,
    name: 'DALOA',
    country: "CÔTE D'IVOIRE",
    latitude: 6.8774,
    longitude: -6.4502,
  },
  {
    id: 161,
    name: 'Fatick',
    country: 'Sénégal',
    latitude: 14.3439,
    longitude: -16.4154,
  },
  {
    id: 162,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6492,
    longitude: -8.0129,
  },
  {
    id: 163,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3614,
    longitude: -1.5897,
  },
  {
    id: 164,
    name: 'Parakou',
    country: 'Bénin',
    latitude: 9.3467,
    longitude: 2.609,
  },
  {
    id: 165,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0483,
    longitude: 9.7043,
  },
  {
    id: 166,
    name: 'Abuja',
    country: 'Nigeria',
    latitude: 9.05785,
    longitude: 7.49508,
  },
  {
    id: 167,
    name: 'Abomey-Calavi',
    country: 'Bénin',
    latitude: 6.4485,
    longitude: 2.3557,
  },
  {
    id: 1607,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3741,
    longitude: -1.5692,
  },
  {
    id: 168,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3811,
    longitude: -1.5292,
  },
  {
    id: 169,
    name: 'Alexandrie',
    country: 'Égypte',
    latitude: 31.2156,
    longitude: 29.9553,
  },
  {
    id: 170,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 171,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3811,
    longitude: -1.5392,
  },
  {
    id: 172,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3811,
    longitude: -1.5392,
  },
  {
    id: 173,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 174,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6828,
    longitude: -17.4467,
  },
  {
    id: 175,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6428,
    longitude: -17.4467,
  },
  {
    id: 176,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3411,
    longitude: -1.5192,
  },
  {
    id: 177,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.3486,
    longitude: -4.0844,
  },
  {
    id: 178,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3811,
    longitude: -1.5292,
  },
  {
    id: 179,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1334,
    longitude: 1.2416,
  },
  {
    id: 180,
    name: 'Guider',
    country: 'Cameroun',
    latitude: 10.1905,
    longitude: 14.0874,
  },
  {
    id: 181,
    name: "Fada N'Gourma",
    country: 'Burkina Faso',
    latitude: 12.0817,
    longitude: 0.6347,
  },
  {
    id: 182,
    name: 'Nancy',
    country: 'France',
    latitude: 48.6921,
    longitude: 6.1844,
  },
  {
    id: 183,
    name: 'Fatick',
    country: 'Sénégal',
    latitude: 14.1833,
    longitude: -16.1833,
  },
  {
    id: 184,
    name: 'Ngaoundéré',
    country: 'Cameroun',
    latitude: 7.3392,
    longitude: 13.5828,
  },
  {
    id: 185,
    name: 'Porto-Novo',
    country: 'Bénin',
    latitude: 6.4969,
    longitude: 2.6,
  },
  {
    id: 189,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6128,
    longitude: -17.4467,
  },
  {
    id: 186,
    name: 'Bobo-Dioulasso',
    country: 'Burkina Faso',
    latitude: 11.1804,
    longitude: -4.2968,
  },
  {
    id: 187,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 188,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 189,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6728,
    longitude: -17.4467,
  },
  {
    id: 190,
    name: 'Bobo-Dioulasso',
    country: 'Burkina Faso',
    latitude: 11.1404,
    longitude: -4.2968,
  },
  {
    id: 191,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.356,
    longitude: -4.0544,
  },
  {
    id: 192,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0561,
    longitude: 9.7185,
  },
  {
    id: 193,
    name: 'Kigali',
    country: 'Rwanda',
    latitude: -1.9579,
    longitude: 30.1128,
  },
  {
    id: 1708,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3911,
    longitude: -1.5192,
  },
  {
    id: 1780,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3711,
    longitude: -1.5392,
  },
  {
    id: 194,
    name: 'Brazzaville',
    country: 'République du Congo',
    latitude: -4.2634,
    longitude: 15.2428,
  },
  {
    id: 195,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6892,
    longitude: -8.0029,
  },
  {
    id: 196,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3559,
    longitude: 2.3276,
  },
  {
    id: 197,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3859,
    longitude: 2.3276,
  },
  {
    id: 1880,
    name: 'Yaoundé',
    country: 'Cameroun',
    latitude: 3.848,
    longitude: 11.5021,
  },
  {
    id: 198,
    name: 'MAROC',
    country: 'Maroc',
    latitude: 31.7517,
    longitude: -7.0926,
  },
  {
    id: 199,
    name: 'Douala',
    country: 'Cameroun',
    latitude: 4.0581,
    longitude: 9.7095,
  },
  {
    id: 200,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1834,
    longitude: 1.2316,
  },
  {
    id: 201,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6628,
    longitude: -17.4467,
  },
  {
    id: 202,
    name: 'Bobo-Dioulasso',
    country: 'Burkina Faso',
    latitude: 11.1804,
    longitude: -4.2868,
  },
  {
    id: 203,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3911,
    longitude: -1.5792,
  },
  {
    id: 204,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3911,
    longitude: -1.5492,
  },
  {
    id: 205,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3611,
    longitude: -1.5812,
  },
  {
    id: 206,
    name: 'Rufisque',
    country: 'Sénégal',
    latitude: 14.7172,
    longitude: -17.2211,
  },
  {
    id: 207,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6928,
    longitude: -17.4367,
  },
  {
    id: 208,
    name: 'Kaolack',
    country: 'Sénégal',
    latitude: 14.1667,
    longitude: -15.7167,
  },
  {
    id: 209,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3311,
    longitude: -1.5892,
  },
  {
    id: 210,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1864,
    longitude: 1.2616,
  },
  {
    id: 211,
    name: 'Tchad',
    country: 'Tchad',
    latitude: 15.4542,
    longitude: 18.7322,
  },
  {
    id: 212,
    name: 'Bamako',
    country: 'Mali',
    latitude: 12.6692,
    longitude: -8.0089,
  },
  {
    id: 213,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3811,
    longitude: -1.5892,
  },
  {
    id: 214,
    name: 'Djibouti',
    country: 'Djibouti',
    latitude: 11.8251,
    longitude: 42.5903,
  },
  {
    id: 215,
    name: 'Segou',
    country: 'Mali',
    latitude: 13.4283,
    longitude: -6.1716,
  },
  {
    id: 216,
    name: 'Moncton',
    country: 'Canada',
    latitude: 46.0894,
    longitude: -64.7782,
  },
  {
    id: 217,
    name: 'Conakry',
    country: 'Guinée',
    latitude: 9.6612,
    longitude: -13.5784,
  },
  {
    id: 218,
    name: 'Chipata',
    country: 'Zambie',
    latitude: -13.6428,
    longitude: 32.6528,
  },
  {
    id: 219,
    name: 'Montreal',
    country: 'Canada',
    latitude: 45.5188,
    longitude: -73.5878,
  },
  {
    id: 220,
    name: 'Paris',
    country: 'France',
    latitude: 48.8766,
    longitude: 2.3522,
  },
  {
    id: 221,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.366,
    longitude: -4.0044,
  },
  {
    id: 222,
    name: 'Dakar',
    country: 'Sénégal',
    latitude: 14.6928,
    longitude: -17.4667,
  },
  {
    id: 223,
    name: 'Cotonou',
    country: 'Bénin',
    latitude: 6.3859,
    longitude: 2.4476,
  },
  {
    id: 224,
    name: 'Faranah',
    country: 'Guinée',
    latitude: 10.0167,
    longitude: -10.0167,
  },
  {
    id: 225,
    name: 'Kinshasa',
    country: 'RDC',
    latitude: -4.3216,
    longitude: 15.2999,
  },
  {
    id: 226,
    name: 'Abidjan',
    country: "Côte d'Ivoire",
    latitude: 5.356,
    longitude: -4.0244,
  },
  {
    id: 227,
    name: 'Ouagadougou',
    country: 'Burkina Faso',
    latitude: 12.3911,
    longitude: -1.5092,
  },
  {
    id: 228,
    name: 'Lomé',
    country: 'Togo',
    latitude: 6.1834,
    longitude: 1.2116,
  },
  {
    id: 229,
    name: 'KANANGA',
    country: 'République Démocratique du Congo',
    latitude: -5.9,
    longitude: 22.4167,
  },
  {
    id: 230,
    name: "N'Djamena",
    country: 'Tchad',
    latitude: 12.1548,
    longitude: 15.0557,
  },
];
