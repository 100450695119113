const BookingEvent = () => {
  return (
    <div className='md:h-[40vh] py-5 md:py-0  bg-[#e7e7e7]'>
      <div className=' w-[95%] md:w-[80%] h-full flex-col md:flex-row flex items-center mx-auto'>
        <div className=' w-[50%]'>
          <span className='text-[#ed4f91] font-medium '>
            En cas de problème
          </span>
          <h3 className=' font-bold text-[1.5rem]'>+330056456476</h3>
          <span>Comité d'évènement</span>
        </div>
        <div className=' relative w-full md:w-[50%]'>
          <form
            className='space-y-4 rounded-md  right-0 left-0 -top-[16rem] md:absolute p-7 bg-white shadow-lg'
            action=''
          >
            <div className='  justify-center w-full items-center flex text-[1.2rem]'>
              <h1 className=' text-[1.2rem] font-semibold'>S'inscrire</h1>
            </div>

            <div className='  flex flex-col'>
              <label htmlFor='nom'>Nom Complet</label>
              <input
                className=' border-[#d5d5d5] outline-none border-[1px] p-2 rounded-md'
                type='text'
                placeholder='Nom Complet'
              />
            </div>
            <div className=' flex flex-col'>
              <label htmlFor='téléphone'>Téléphone</label>
              <input
                className=' border-[#d5d5d5] outline-none border-[1px] p-2 rounded-md'
                type='text'
                placeholder='téléphone'
              />
            </div>
            <div className=' flex flex-col'>
              <label htmlFor='email'>Email</label>
              <input
                className=' border-[#d5d5d5] outline-none border-[1px] p-2 rounded-md'
                type='email'
                placeholder='Email'
              />
            </div>
            <div className=' w-full'>
              <button className=' w-full text-white py-2 px-6 bg-[#ed4f91]'>
                S'inscrire
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingEvent;
