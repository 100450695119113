import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase/config';
import ButtonSpinner from '../../utilities/ButtonSpinner';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const functions = getFunctions(app);
      const sendEmail = httpsCallable(functions, 'sendEmail');

      const result = await sendEmail({ name, email, message });
      if (result.data.success) {
        setMessage('');
        setEmail('');
        setName('');
        setLoading(false);
        setSuccess('Email envoyé avec succès!');
        setTimeout(() => {
          setSuccess('');
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de l'envoi de l'email :", error);
      setError("Erreur lors de l'envoi de l'email");
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <div className=' p-2 md:p-6 bg-[#f3f6f8] rounded-md shadow-md'>
      <h2 className='md:text-[1.2rem] font-bold mb-4 text-[#00075e]'>
        Envoyez-nous un message
      </h2>
      <span className=' text-[#34881d] text-[1.1rem]'> {success}</span>
      <span className=' text-[#de4040] text-[1.1rem]'> {error}</span>
      <form onSubmit={sendEmail}>
        <div className='mb-4'>
          <label className='block text-gray-700'>Votre nom</label>
          <input
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            type='text'
            className='w-full p-2 outline-none border border-gray-300 rounded'
            placeholder='Votre nom'
          />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Votre addresse e-mail</label>
          <input
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            className='w-full outline-none p-2 border border-gray-300 rounded'
            placeholder='Votre addresse e-mail'
          />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Message</label>
          <textarea
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={4}
            className='w-full outline-none resize-none p-2 border border-gray-300 rounded'
            placeholder='Votre message'
          />
        </div>
        <button
          type='submit'
          className='bg-[#00075e] text-white py-2 px-6 rounded-full  hover:bg-[#01064b]'
        >
          {loading ? <ButtonSpinner loading={loading} /> : 'Envoyer'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
