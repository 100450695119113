import React from 'react';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/carousel_8.png';
import News from '../componants/News/News';
import JoindUs from '../componants/Home/JoindUs';
import { useCollection } from '../hooks/useCollection';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../componants/utilities/Spinner';

const Actualites = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const search = searchParams.get('search');

  let docs = [];

  const filters =
    query === 'Paroles'
      ? "Paroles d'Experts"
      : query === 'Partenariats'
      ? 'Partenariats'
      : query === 'Tribune'
      ? 'Tribune'
      : query === 'Articles'
      ? 'Articles'
      : null;

  const pageHeader = {
    title: 'Actualités',
    description:
      'Consultation médicale, télémédecine, formations professionnelles, services de santé spécialisés, gestion de cas.',
    banner: banner,
  };

  const { documents, loading, error } = useCollection('blogs');
  docs = documents;

  if (filters !== null) {
    docs = docs.filter((doc) => doc.category === filters);
  }

  // Filtrer par recherche si une recherche est appliquée
  if (search) {
    docs = docs.filter((doc) =>
      doc.title.toLowerCase().includes(search.toLowerCase())
    );
  }

  // Trier les documents par date de création
  docs = docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // docs =
  //   filters !== null
  //     ? documents
  //         .filter((doc) => doc.category === filters)
  //         .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //     : search
  //     ? documents
  //         .filter((doc) => doc.title >= search)
  //         .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //     : documents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      {loading ? (
        <div className=' flex text-center flex-col w-[95%] md:w-[80%] mx-auto h-[30vh] items-center justify-center'>
          <Spinner loading={loading} />
        </div>
      ) : (
        <News news={docs} />
      )}

      <JoindUs />
    </main>
  );
};

export default Actualites;
