const PageHeader = ({ w, pageHeader }) => {
  return (
    <div
      style={{
        background: `url(${pageHeader.banner})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
      }}
      className=' h-[70vh] w-full flex items-end'
    >
      <div className=' w-full bg-white h-[100%] flex items-end pageHeader'>
        <div className={` w-[95%] md:w-[${w}] mx-auto`}>
          <h2 className=' text-[1.5rem] md:text-[2rem] xl:text-[3rem] font-semibold   text-[#00075e]'>
            {pageHeader.title}
          </h2>
          <p className='text-[#474747] text-[0.9rem] md:text-[1.2rem]'>
            {pageHeader.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
