import React from 'react';
import { Link } from 'react-router-dom';

const FooterMenu = () => {
  return (
    <div>
      <div className='  py-1 text-white relative z-30 bg-[#00075e] '>
        <ul className=' w-[95%] mx-auto capitalize md:gap-3  flex flex-col space-y-0  md:grid sm:grid-cols-5 text-[1.2rem]'>
          <li className='md:py-[0.9rem] md:text-[1.2rem]  text-[1.1rem] py-1 transition-all foote__item'>
            <Link to='/evenements'>Événements</Link>
          </li>
          <li className='md:py-[0.9rem] py-1 md:text-[1.2rem]  text-[1rem]   transition-all  foote__item'>
            <Link to='/apropos-de-nous'>À propos</Link>
          </li>
          <li className='md:py-[0.9rem] md:text-[1.2rem]  text-[1.1rem]  py-1 transition-all foote__item'>
            <Link to='/contactez-nous'>Contactez-nous</Link>
          </li>
          <li className='md:py-[0.9rem] md:text-[1.2rem]  text-[1.1rem] py-1 transition-all foote__item'>
            <Link to='/activite'>Nos activités</Link>
          </li>
          <li className='md:py-[0.9rem] py-1 md:text-[1.2rem]  text-[1.1rem]  transition-all foote__item'>
            <Link to='/experts'>Expert</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterMenu;
