import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/carousel_8.png';

const NosValeurs = () => {
  const pageHeader = {
    title: 'Nos Valeurs',
    description:
      "Engagement envers l'excellence, l'intégrité, la transparence et l'innovation dans tous nos projets de santé.",
    banner: banner,
  };

  const heroText = `Le CEISNA promeut une santé numérique inclusive en Afrique, valorisant équité, innovation, collaboration, éthique, renforcement des capacités, et impact durable pour transformer les systèmes de santé africains.`;

  const listElements = {
    title: 'Accessibilité et Inclusion:',
    enum: [
      "Promouvoir l'accès équitable aux solutions de santé numérique.",
      "Garantir l'inclusion de toutes les populations.",
      'Réduire les disparités socio-économiques et géographiques.',
      "Faciliter l'accès aux soins pour tous.",
    ],
  };
  const listElements2 = {
    title: 'Innovation et Durabilité :',
    enum: [
      'Développer des technologies de santé numériques adaptées.',
      "Encourager l'innovation continue dans le domaine de la santé.",
      'Assurer la pérennité des solutions mises en place.',
      'Intégrer les nouvelles technologies de manière durable.',
    ],
  };

  const accordions = [
    {
      title: 'Éthique et Respect de la Vie Privée',
      content:
        "Nous veillons au respect de la confidentialité et à la protection des données personnelles des patients, tout en promouvant des pratiques éthiques dans le développement et l'utilisation des technologies de santé numériques.",
    },
    {
      title: 'Renforcement des Capacités',
      content:
        'Nous soutenons le développement des compétences et des connaissances des professionnels de santé en matière de santé numérique et favorisons le transfert de compétences et la formation des communautés locales.',
    },
    {
      title: 'Impact et Transformation',
      content:
        'Nous nous assurons que les solutions de santé numérique aient un impact positif et mesurable sur la santé des populations africaines et contribuent à la transformation durable des systèmes de santé grâce au numérique.',
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default NosValeurs;
