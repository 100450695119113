import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const ContactSection = () => {
  return (
    <div className=' py-7'>
      <div className=' flex-col md:flex-row flex w-[95%]  md:w-[80%] md:space-x-6 mx-auto'>
        <div className=' w-[100%] md:w-[50%]'>
          <ContactForm />
        </div>
        <div className=' w-[100%] md:w-[50%]'>
          <ContactInfo />
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
