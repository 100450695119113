import JoindUs from '../componants/Home/JoindUs';
import MissionContent from '../componants/OurMission/MissionContent';
import MissionHero from '../componants/OurMission/MissionHero';
import MissionThree from '../componants/utilities/MissionThree';
import PageHeader from '../componants/utilities/PageHeader';
import banner from '../public/images/groupe-consultatif.png';

const GroupeConsultatif = () => {
  const pageHeader = {
    title: 'Groupe de Consultation sur les Affaires Réglementaires',
    description:
      'Le Groupe consultatif sur les affaires réglementaires fournit des conseils sur les politiques et la conformité réglementaire.',
    banner: banner,
  };

  const heroText = `Introduction : Le Groupe de Consultation sur les Affaires Réglementaires du CEISNA se concentre sur l'assurance de la conformité des initiatives en santé numérique avec les réglementations et normes en vigueur, en fournissant des conseils et des recommandations aux parties prenantes.`;

  const listElements = {
    title: 'Missions:',
    enum: [
      'Veiller à la conformité des projets de santé numérique avec les réglementations locales et internationales.',
      'Développer des lignes directrices et des recommandations réglementaires.',
      'Assurer une veille sur les évolutions législatives et réglementaires.',
      "Organiser des sessions d'information et de formation sur les affaires réglementaires.",
    ],
  };
  const listElements2 = {
    title: 'Propositions de Valeur:',
    enum: [
      "Conseils d'experts sur la conformité réglementaire.",
      'Accès à des informations à jour sur les réglementations et les normes.',
      "Support pour la certification et l'accréditation des technologies de santé numérique.",
      'Réduction des risques liés à la non-conformité.',
    ],
  };

  const accordions = [
    {
      title:
        ' Comment puis-je obtenir des conseils sur les affaires réglementaires pour mon projet ?',
      content:
        ' Vous pouvez nous contacter directement pour organiser une consultation avec nos experts en réglementation.',
    },
    {
      title:
        'Le CEISNA offre-t-il des formations sur les réglementations en santé numérique ? ',
      content:
        "Oui, nous organisons régulièrement des sessions de formation et d'information sur les affaires réglementaires.",
    },
    {
      title:
        ' Quels sont les principaux défis réglementaires en santé numérique en Afrique ? ',
      content:
        " Les défis incluent la diversité des réglementations locales, la mise à jour constante des normes, et la nécessité de sensibiliser les parties prenantes à l'importance de la conformité.",
    },
  ];

  return (
    <main>
      <PageHeader w={'80%'} pageHeader={pageHeader} />
      <MissionHero heroText={heroText} />
      <MissionContent
        listElements={listElements}
        listElements2={listElements2}
        accordions={accordions}
      />
      <JoindUs />
      <MissionThree />
    </main>
  );
};

export default GroupeConsultatif;
